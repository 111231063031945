import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(PATH_AUTH.login);
    }
  }, [navigate, isAuthenticated]);

  return <>{children}</>;
}
