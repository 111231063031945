import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />
    },
    {
      path: 'auth',
      element: <GuestGuard />,
      children: [
        {
          path: 'login',
          element: <Login />
        },
        { path: 'verify', element: <VerifyCode /> },
        { path: '*', element: <Navigate to="/auth/login" replace /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/user" replace /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            { path: ':id', element: <UserDetail /> }
          ]
        },
        {
          path: 'document',
          children: [
            { element: <Navigate to="/dashboard/document/list" replace /> },
            { path: 'list', element: <DocumentList /> }
          ]
        },
        {
          path: 'ticket',
          children: [
            { element: <Navigate to="/dashboard/ticket/list" replace /> },
            { path: 'list', element: <TicketList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id', element: <TicketDetail /> }
          ]
        },
        {
          path: 'supportAdmin',
          children: [
            { element: <Navigate to="/dashboard/supportAdmin/list" replace /> },
            { path: 'list', element: <SupportAdminList /> },
            { path: 'new', element: <SupportAdminCreate /> },
            { path: ':id/edit', element: <SupportAdminCreate /> },
            { path: ':id', element: <SupportAdminDetail /> }
          ]
        },
        {
          path: 'bankAccount',
          children: [
            { element: <Navigate to="/dashboard/bankAccount/list" replace /> },
            { path: 'list', element: <BankAccountList /> },
            { path: ':id/edit', element: <BankAccountEdit /> },
            { path: ':id', element: <BankAccountDetail /> }
          ]
        },
        {
          path: 'transaction',
          children: [
            { element: <Navigate to="/dashboard/transaction/list" replace /> },
            { path: 'list', element: <TransactionList /> },
            { path: ':id/edit', element: <GatewayCreate /> },
            { path: ':id', element: <TransactionDetail /> }
          ]
        },
        {
          path: 'gateway',
          children: [
            { element: <Navigate to="/dashboard/gateway/list" replace /> },
            { path: 'list', element: <GatewayList /> },
            { path: 'new', element: <GatewayCreate /> },
            { path: ':id/edit', element: <GatewayCreate /> },
            { path: ':id', element: <GatewayDetail /> }
          ]
        },
        {
          path: 'bank-gateway',
          children: [
            { element: <Navigate to="/dashboard/bank-gateway/list" replace /> },
            { path: 'list', element: <BankGatewayList /> },
            { path: 'new', element: <BankGatewayCreate /> },
            { path: ':id/edit', element: <BankGatewayCreate /> }
          ]
        },
        {
          path: 'market',
          children: [
            { element: <Navigate to="/dashboard/market/list" replace /> },
            { path: 'list', element: <MarketList /> },
            { path: 'new', element: <MarketCreate /> },
            { path: ':id/edit', element: <MarketCreate /> }
          ]
        },
        {
          path: 'rate',
          children: [
            { element: <Navigate to="/dashboard/rate/list" replace /> },
            { path: 'list', element: <RateList /> },
            { path: 'new', element: <RateCreate /> },
            { path: ':id/edit', element: <RateCreate /> },
            { path: ':id', element: <RateDetail /> }
          ]
        },
        {
          path: 'network',
          children: [
            { element: <Navigate to="/dashboard/network/list" replace /> },
            { path: 'list', element: <NetworkList /> },
            { path: 'new', element: <NetworkCreate /> },
            { path: ':id/edit', element: <NetworkCreate /> },
            { path: ':id', element: <NetworkDetail /> }
          ]
        },
        {
          path: 'order',
          children: [{ element: <Navigate to="/dashboard/trade/list" replace /> }]
        },
        {
          path: 'deposit',
          children: [
            { element: <Navigate to="/dashboard/deposit/list" replace /> },
            { path: 'list', element: <DepositList /> },
            { path: ':id', element: <DepositDetail /> }
          ]
        },
        {
          path: 'withdrawal',
          children: [
            { element: <Navigate to="/dashboard/withdrawal/list" replace /> },
            { path: 'list', element: <WithdrawalList /> },
            { path: ':id', element: <WithdrawalDetail /> }
          ]
        },
        {
          path: 'trade',
          children: [
            { element: <Navigate to="/dashboard/trade/list" replace /> },
            { path: 'list', element: <TradeList /> },
            { path: ':id', element: <TradeDetail /> }
          ]
        },
        {
          path: 'exchange',
          children: [
            { element: <Navigate to="/dashboard/exchange/list" replace /> },
            { path: 'list', element: <ExchangeList /> },
            { path: ':id', element: <ExchangeDetail /> }
          ]
        },
        {
          path: 'announcement',
          children: [
            { element: <Navigate to="/dashboard/announcement/list" replace /> },
            { path: 'list', element: <AnnouncementList /> },
            { path: 'new', element: <AnnouncementCreate /> },
            { path: ':id/edit', element: <AnnouncementCreate /> },
            { path: ':id', element: <AnnouncementDetail /> }
          ]
        },
        {
          path: 'app-slider',
          children: [
            { element: <Navigate to="/dashboard/app-slider/list" replace /> },
            { path: 'list', element: <AppSliderList /> },
            { path: 'new', element: <AppSliderCreate /> },
            { path: ':id/edit', element: <AppSliderCreate /> }
          ]
        },
        {
          path: 'total-wallet',
          children: [
            { element: <Navigate to="/dashboard/total-wallet/list" replace /> },
            { path: 'list', element: <TotalWalletList /> },
            { path: ':id', element: <UsersWalletList /> }
          ]
        },
        {
          path: 'settings',
          element: <SettingUpdate />
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/user/UserCreate')));
const UserDetail = Loadable(lazy(() => import('../pages/dashboard/user/UserDetail')));
const DocumentList = Loadable(lazy(() => import('../pages/dashboard/user-document/DocumentList')));
const TicketList = Loadable(lazy(() => import('../pages/dashboard/ticket/TicketList')));
const TicketDetail = Loadable(lazy(() => import('../pages/dashboard/ticket/TicketDetail')));
const BankAccountList = Loadable(
  lazy(() => import('../pages/dashboard/bankAccount/BankAccountList'))
);
const BankAccountEdit = Loadable(
  lazy(() => import('../pages/dashboard/bankAccount/BankAccountEdit'))
);
const BankAccountDetail = Loadable(
  lazy(() => import('../pages/dashboard/bankAccount/BankAccountDetail'))
);
const TransactionList = Loadable(
  lazy(() => import('../pages/dashboard/transaction/TransactionList'))
);
const TransactionDetail = Loadable(
  lazy(() => import('../pages/dashboard/transaction/TransactionDetail'))
);
const GatewayList = Loadable(lazy(() => import('../pages/dashboard/gateway/GatewayList')));
const GatewayCreate = Loadable(lazy(() => import('../pages/dashboard/gateway/GatewayCreate')));
const GatewayDetail = Loadable(lazy(() => import('../pages/dashboard/gateway/GatewayDetail')));
const BankGatewayList = Loadable(
  lazy(() => import('../pages/dashboard/bank-gateway/BankGatewayList'))
);
const BankGatewayCreate = Loadable(
  lazy(() => import('../pages/dashboard/bank-gateway/BankGatewayCreate'))
);
const MarketList = Loadable(lazy(() => import('../pages/dashboard/market/MarketList')));
const MarketCreate = Loadable(lazy(() => import('../pages/dashboard/market/MarketCreate')));
const RateList = Loadable(lazy(() => import('../pages/dashboard/rate/RateList')));
const RateCreate = Loadable(lazy(() => import('../pages/dashboard/rate/RateCreate')));
const RateDetail = Loadable(lazy(() => import('../pages/dashboard/rate/RateDetail')));
const NetworkList = Loadable(lazy(() => import('../pages/dashboard/network/NetworkList')));
const NetworkCreate = Loadable(lazy(() => import('../pages/dashboard/network/NetworkCreate')));
const NetworkDetail = Loadable(lazy(() => import('../pages/dashboard/network/NetworkDetail')));
const DepositList = Loadable(lazy(() => import('../pages/dashboard/deposit/DepositList')));
const DepositDetail = Loadable(lazy(() => import('../pages/dashboard/deposit/DepositDetail')));
const WithdrawalList = Loadable(lazy(() => import('../pages/dashboard/withdrawal/WithdrawalList')));
const WithdrawalDetail = Loadable(
  lazy(() => import('../pages/dashboard/withdrawal/WithdrawalDetail'))
);
const TradeList = Loadable(lazy(() => import('../pages/dashboard/trade/TradeList')));
const TradeDetail = Loadable(lazy(() => import('../pages/dashboard/trade/TradeDetail')));
const ExchangeList = Loadable(lazy(() => import('../pages/dashboard/exchange/ExchangeList')));
const ExchangeDetail = Loadable(lazy(() => import('../pages/dashboard/exchange/ExchangeDetail')));
const AnnouncementList = Loadable(
  lazy(() => import('../pages/dashboard/announcement/AnnouncementList'))
);
const AnnouncementCreate = Loadable(
  lazy(() => import('../pages/dashboard/announcement/AnnouncementCreate'))
);
const AnnouncementDetail = Loadable(
  lazy(() => import('../pages/dashboard/announcement/AnnouncementDetail'))
);
const AppSliderList = Loadable(lazy(() => import('../pages/dashboard/app-slider/AppSliderList')));
const AppSliderCreate = Loadable(
  lazy(() => import('../pages/dashboard/app-slider/AppSliderCreate'))
);
const SupportAdminList = Loadable(
  lazy(() => import('../pages/dashboard/supportAdmin/SupportAdminList'))
);
const SupportAdminCreate = Loadable(
  lazy(() => import('../pages/dashboard/supportAdmin/SupportAdminCreate'))
);
const SupportAdminDetail = Loadable(
  lazy(() => import('../pages/dashboard/supportAdmin/SupportAdminDetail'))
);
const TotalWalletList = Loadable(
  lazy(() => import('../pages/dashboard/total-wallet/TotalWalletList'))
);
const UsersWalletList = Loadable(
  lazy(() => import('../pages/dashboard/total-wallet/UsersWalletList'))
);
const SettingUpdate = Loadable(lazy(() => import('../pages/dashboard/SettingUpdate')));
