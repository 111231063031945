import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddMessageToTicket = {
  content?: Maybe<Scalars['String']>;
  ticketId: Scalars['String'];
};

export type Admin = {
  __typename?: 'Admin';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  role: IRoles;
  secret?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AdminCreateTicketInput = {
  content: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['String'];
};

export type AdminCreateUpdateAnnouncementInput = {
  content: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lang: Languages;
  title: Scalars['String'];
};

export type AdminCreateUpdateAppSliderInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lang: Languages;
  name?: Maybe<Scalars['String']>;
  type: SliderType;
};

export type AdminCreateUpdateBankGatewayInput = {
  destinationCartNumber?: Maybe<Scalars['String']>;
  destinationIban?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  merchantId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  persianName: Scalars['String'];
  type: BankGatewayType;
};

export type AdminCreateUpdateGatewayInput = {
  allowSend?: Maybe<Scalars['Boolean']>;
  bestCode?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  field_1?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAuto?: Maybe<Scalars['Boolean']>;
  isCrypto?: Maybe<Scalars['Boolean']>;
  maxAmount: Scalars['String'];
  merchant: MerchantType;
  minAmount: Scalars['String'];
  name: Scalars['String'];
  persianName: Scalars['String'];
  /** Wil be used only on creation time for the subsequent generating rates */
  ratePercent?: Maybe<Scalars['Float']>;
  requireVerify?: Maybe<Scalars['Boolean']>;
  reserve?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type AdminCreateUpdateMarketInput = {
  fromGatewayId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  toGatewayId: Scalars['String'];
  type?: Maybe<MarketType>;
};

export type AdminCreateUpdateNetworkInput = {
  gatewayId: Scalars['String'];
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  network: Scalars['String'];
  networkName: Scalars['String'];
};

export type AdminCreateUpdateNotificationInput = {
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AdminCreateUpdateRateInput = {
  id?: Maybe<Scalars['String']>;
  isAuto?: Maybe<Scalars['Boolean']>;
  rateFrom?: Maybe<Scalars['String']>;
  ratePercent?: Maybe<Scalars['Float']>;
  rateTo?: Maybe<Scalars['String']>;
  receiveGatewayId: Scalars['String'];
  sendGatewayId: Scalars['String'];
};

export type AdminCreateUpdateSettingInput = {
  adminMobile?: Maybe<Scalars['String']>;
  bronzeDailyWithdraw: Scalars['Float'];
  bronzeTradeCommission: Scalars['Float'];
  /** Dynamic order validation window in hours */
  dynamicOrderWindow?: Maybe<Scalars['Int']>;
  goldDailyWithdraw: Scalars['Float'];
  goldTradeCommission: Scalars['Float'];
  /** None crypto order validation window in minutes */
  nonCryptoOrderWindow?: Maybe<Scalars['Int']>;
  normalDailyWithdraw: Scalars['Float'];
  normalTradeCommission: Scalars['Float'];
  referralCommission?: Maybe<Scalars['String']>;
  silverDailyWithdraw: Scalars['Float'];
  silverTradeCommission: Scalars['Float'];
  /** Static order validation window in minutes */
  staticOrderWindow?: Maybe<Scalars['Int']>;
  /** Static price validation window in seconds */
  staticPriceWindow?: Maybe<Scalars['Int']>;
  usdPrice?: Maybe<Scalars['String']>;
  vipDailyWithdraw: Scalars['Float'];
  vipTradeCommission: Scalars['Float'];
};

export type AdminCreateUpdateSupportAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: IRoles;
};

export type AdminCreateUpdateUserInput = {
  address?: Maybe<Scalars['String']>;
  birthCertificateCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified?: Maybe<Scalars['Boolean']>;
  fatherName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invitedByUserId?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  mobileVerified?: Maybe<Scalars['Boolean']>;
  nationalCode?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
};

export type AdminCreditDebitUserWalletInput = {
  amount: Scalars['String'];
  credit: Scalars['Boolean'];
  gatewayId: Scalars['String'];
  userId: Scalars['String'];
};

export type AdminGetBankAccountsFilterInput = {
  cardNumber?: Maybe<Scalars['String']>;
};

export type AdminGetNetworksFilterInput = {
  network?: Maybe<Scalars['String']>;
  networkName?: Maybe<Scalars['String']>;
};

export type AdminGetRatesFilterInput = {
  gatewayName?: Maybe<Scalars['String']>;
  receiveGateway?: Maybe<Scalars['String']>;
  sendGateway?: Maybe<Scalars['String']>;
};

export type AdminGetUserWalletsFilterInput = {
  gatewayId?: Maybe<Scalars['String']>;
  positiveAmountsOnly?: Maybe<Scalars['Boolean']>;
};

export type AdminGetUsersFilterInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AdminLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type AdminLoginResponse = {
  __typename?: 'AdminLoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  admin?: Maybe<Admin>;
  otpAuthUrl?: Maybe<Scalars['String']>;
};

export type AdminUpdateBankAccountInput = {
  bank?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isVerify?: Maybe<Scalars['Boolean']>;
  shebaNumber?: Maybe<Scalars['String']>;
};

export type AdminUpdateTicketStatusInput = {
  status: TicketStatus;
  ticketId: Scalars['String'];
};

export type AdminUpdateUserDocumentStatusInput = {
  id?: Maybe<Scalars['String']>;
  status: DocumentStatus;
};

export type AdminUpdateUserReviewInput = {
  comment?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lang: Languages;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AnnouncementPaginationResponse = {
  __typename?: 'AnnouncementPaginationResponse';
  announcements: Array<Announcement>;
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
};

export type AppSlider = {
  __typename?: 'AppSlider';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageLink: Scalars['String'];
  lang: Languages;
  name?: Maybe<Scalars['String']>;
  type: SliderType;
  updatedAt: Scalars['DateTime'];
};

export type AppSliderPaginationResponse = {
  __typename?: 'AppSliderPaginationResponse';
  appsliders: Array<AppSlider>;
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  bank: Scalars['String'];
  cardNumber: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isVerify: Scalars['Boolean'];
  shebaNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type BankAccountPaginationResponse = {
  __typename?: 'BankAccountPaginationResponse';
  bankaccounts: Array<BankAccount>;
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
};

export type BankGateway = {
  __typename?: 'BankGateway';
  createdAt: Scalars['DateTime'];
  destinationCartNumber?: Maybe<Scalars['String']>;
  destinationIban?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  merchantId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  persianName: Scalars['String'];
  type: BankGatewayType;
  updatedAt: Scalars['DateTime'];
};

export type BankGatewayPaginationResponse = {
  __typename?: 'BankGatewayPaginationResponse';
  bankgateways: Array<BankGateway>;
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
};

export enum BankGatewayType {
  BankTransfer = 'BANK_TRANSFER',
  Zibal = 'ZIBAL'
}

export type BinanceDepth = {
  __typename?: 'BinanceDepth';
  /** Event time */
  E?: Maybe<Scalars['Float']>;
  /** First update ID in event */
  U?: Maybe<Scalars['Float']>;
  /** Asks to be updated */
  a?: Maybe<Array<Array<Scalars['String']>>>;
  /** Bids to be updated */
  b?: Maybe<Array<Array<Scalars['String']>>>;
  /** Event type */
  e?: Maybe<Scalars['String']>;
  /** Symbol */
  s?: Maybe<Scalars['String']>;
  /** Final update ID in event */
  u?: Maybe<Scalars['Float']>;
};

export type BinanceSpotAssetBalance = {
  __typename?: 'BinanceSpotAssetBalance';
  asset?: Maybe<Scalars['String']>;
  free?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['String']>;
};

export type BinanceTrade = {
  __typename?: 'BinanceTrade';
  /** Event time */
  E?: Maybe<Scalars['Float']>;
  /** Ignore */
  M?: Maybe<Scalars['Boolean']>;
  /** Trade time */
  T?: Maybe<Scalars['Float']>;
  /** Seller order ID */
  a?: Maybe<Scalars['Float']>;
  /** Buyer order ID */
  b?: Maybe<Scalars['Float']>;
  /** Event type */
  e?: Maybe<Scalars['String']>;
  /** Is the buyer the market maker? */
  m?: Maybe<Scalars['Boolean']>;
  /** Price */
  p?: Maybe<Scalars['String']>;
  /** Quantity */
  q?: Maybe<Scalars['String']>;
  /** Symbol */
  s?: Maybe<Scalars['String']>;
  /** Trade ID */
  t?: Maybe<Scalars['Float']>;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Convert = {
  __typename?: 'Convert';
  buyCompleted?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  receiveAmount: Scalars['String'];
  receiveCurrency: Scalars['String'];
  receiveGateway: Gateway;
  receiveGatewayId: Scalars['String'];
  receiveRate: Scalars['String'];
  reference: Scalars['String'];
  sellCompleted?: Maybe<Scalars['Boolean']>;
  sendAmount: Scalars['String'];
  sendCurrency: Scalars['String'];
  sendGateway: Gateway;
  sendGatewayId: Scalars['String'];
  sendRate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type ConvertPaginationResponse = {
  __typename?: 'ConvertPaginationResponse';
  converts: Array<Convert>;
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
};

export type CreateBankAccountInput = {
  bank: Scalars['String'];
  cardNumber: Scalars['String'];
  shebaNumber: Scalars['String'];
};

export type CreateBankTransferDepositInput = {
  amount: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  sourceAddress?: Maybe<Scalars['String']>;
  txId: Scalars['String'];
};

export type CreateCryptoDepositInput = {
  amount: Scalars['String'];
  networkId: Scalars['String'];
};

export type CreateLimitTradeOrderInput = {
  amount: Scalars['Float'];
  marketId: Scalars['String'];
  price: Scalars['Float'];
  side: TradeSide;
};

export type CreateMarketTradeOrderInput = {
  amount: Scalars['Float'];
  marketId: Scalars['String'];
  side: TradeSide;
};

export type CreateNonCryptoDepositInput = {
  amount: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
};

export type CreateOcoTradeOrderInput = {
  amount: Scalars['Float'];
  limit: Scalars['Float'];
  marketId: Scalars['String'];
  price: Scalars['Float'];
  side: TradeSide;
  stop: Scalars['Float'];
};

export type CreatePayeerDepositResponse = {
  __typename?: 'CreatePayeerDepositResponse';
  url: Scalars['String'];
  /** Payment URL */
  userDeposit: UserDeposit;
};

export type CreatePerfectDepositResponse = {
  __typename?: 'CreatePerfectDepositResponse';
  payeeAccount: Scalars['String'];
  statusUrl: Scalars['String'];
  userDeposit: UserDeposit;
};

export type CreateStopLimitTradeOrderInput = {
  amount: Scalars['Float'];
  marketId: Scalars['String'];
  /** limit */
  price: Scalars['Float'];
  side: TradeSide;
  stop: Scalars['Float'];
};

export type CreateTicketInput = {
  content: Scalars['String'];
  title: Scalars['String'];
};

export type CreateUserDocumentInput = {
  type: DocumentType;
};

export type CreateWebmoneyDepositResponse = {
  __typename?: 'CreateWebmoneyDepositResponse';
  payeePurse: Scalars['String'];
  resultUrl: Scalars['String'];
  userDeposit: UserDeposit;
};

export type CreateZibalDepositInput = {
  amount: Scalars['String'];
};

export type CreateZibalDepositResponse = {
  __typename?: 'CreateZibalDepositResponse';
  payLink: Scalars['String'];
  userDeposit: UserDeposit;
};

export type CryptoWithdrawInput = {
  amount: Scalars['String'];
  destinationAddress: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  networkId: Scalars['String'];
  twoFaCode: Scalars['String'];
  twoFaType: TwoFaType;
};

export type DepositAddress = {
  __typename?: 'DepositAddress';
  address: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
};

export enum DepositStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  PendingApproval = 'PENDING_APPROVAL',
  PendingPayment = 'PENDING_PAYMENT',
  Refunded = 'REFUNDED'
}

export enum DocumentStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum DocumentType {
  ApprovalVideo = 'APPROVAL_VIDEO',
  AppContract = 'APP_CONTRACT',
  HouseDocument = 'HOUSE_DOCUMENT',
  IdCard = 'ID_CARD',
  Selfie = 'SELFIE'
}

export type ExchangeInfo = {
  __typename?: 'ExchangeInfo';
  rateLimits: Array<RateLimit>;
  serverTime: Scalars['Float'];
  symbols: Array<ExchangeSymbol>;
  timezone: Scalars['String'];
};

export type ExchangeResponse = {
  __typename?: 'ExchangeResponse';
  convert: Convert;
  receivedWallet: UserWallet;
  sentWallet: UserWallet;
};

export type ExchangeSymbol = {
  __typename?: 'ExchangeSymbol';
  allowTrailingStop: Scalars['Boolean'];
  baseAsset: Scalars['String'];
  baseAssetPrecision: Scalars['Float'];
  baseCommissionPrecision: Scalars['Float'];
  cancelReplaceAllowed: Scalars['Boolean'];
  filters: Array<Filter>;
  icebergAllowed: Scalars['Boolean'];
  isMarginTradingAllowed: Scalars['Boolean'];
  isSpotTradingAllowed: Scalars['Boolean'];
  ocoAllowed: Scalars['Boolean'];
  orderTypes: Array<Scalars['String']>;
  permissions: Array<Scalars['String']>;
  quoteAsset: Scalars['String'];
  quoteAssetPrecision: Scalars['Float'];
  quoteCommissionPrecision: Scalars['Float'];
  quoteOrderQtyMarketAllowed: Scalars['Boolean'];
  quotePrecision: Scalars['Float'];
  status: Scalars['String'];
  symbol: Scalars['String'];
};

export type FiatWithdrawInput = {
  address?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  gatewayId: Scalars['String'];
  twoFaCode: Scalars['String'];
  twoFaType: TwoFaType;
};

export type Filter = {
  __typename?: 'Filter';
  applyToMarket?: Maybe<Scalars['Boolean']>;
  avgPriceMins?: Maybe<Scalars['Float']>;
  filterType: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  maxNumAlgoOrders?: Maybe<Scalars['Float']>;
  maxNumOrders?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['String']>;
  maxQty?: Maybe<Scalars['String']>;
  maxTrailingAboveDelta?: Maybe<Scalars['Float']>;
  maxTrailingBelowDelta?: Maybe<Scalars['Float']>;
  minNotional?: Maybe<Scalars['String']>;
  minPrice?: Maybe<Scalars['String']>;
  minQty?: Maybe<Scalars['String']>;
  minTrailingAboveDelta?: Maybe<Scalars['Float']>;
  minTrailingBelowDelta?: Maybe<Scalars['Float']>;
  multiplierDown?: Maybe<Scalars['String']>;
  multiplierUp?: Maybe<Scalars['String']>;
  stepSize?: Maybe<Scalars['String']>;
  tickSize?: Maybe<Scalars['String']>;
};

export type Gateway = {
  __typename?: 'Gateway';
  allowSend: Scalars['Boolean'];
  bestCode?: Maybe<Scalars['String']>;
  coingeckoId: Scalars['String'];
  converts: ConvertPaginationResponse;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  field_1?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isAuto: Scalars['Boolean'];
  isCrypto: Scalars['Boolean'];
  marketCap: Scalars['Float'];
  maxAmount: Scalars['String'];
  merchant: MerchantType;
  minAmount: Scalars['String'];
  myWallet?: Maybe<UserWallet>;
  name: Scalars['String'];
  networks: NetworkPaginationResponse;
  persianName: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  rates: RatePaginationResponse;
  requireVerify: Scalars['Boolean'];
  reserve?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  totalWalletCredit: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userDeposits: UserDepositPaginationResponse;
  userWallets: UserWalletPaginationResponse;
  userWithdrawals: UserWithdrawalPaginationResponse;
  wallets: UserWalletPaginationResponse;
};

export type GatewayConvertsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GatewayNetworksArgs = {
  filter?: Maybe<GetGatewayNetworksFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GatewayRatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GatewayUserDepositsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GatewayUserWalletsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GatewayUserWithdrawalsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GatewayWalletsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GatewayPaginationResponse = {
  __typename?: 'GatewayPaginationResponse';
  count: Scalars['Float'];
  gateways: Array<Gateway>;
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
};

export type GatewaysWithWallet = {
  __typename?: 'GatewaysWithWallet';
  currency: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Float'];
};

export type GetGatewayNetworksFilterInput = {
  onlyActives?: Maybe<Scalars['Boolean']>;
};

export type GetGatewaysFilterInput = {
  nameOrCurrency?: Maybe<Scalars['String']>;
};

export type GetMarketsFilterInput = {
  channel?: Maybe<Scalars['String']>;
};

export type GetRatesFilterInput = {
  receiveGatewayId?: Maybe<Scalars['String']>;
  sendGatewayId?: Maybe<Scalars['String']>;
};

export type GetStaticPriceInput = {
  receiveGatewayId?: Maybe<Scalars['String']>;
  sendGatewayId?: Maybe<Scalars['String']>;
};

export type GetTwoFaMethodsInput = {
  email: Scalars['String'];
};

export enum IRoles {
  Admin = 'ADMIN',
  Support = 'SUPPORT',
  User = 'USER'
}

export type InvitedUser = {
  __typename?: 'InvitedUser';
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum Languages {
  En = 'EN',
  Fa = 'FA'
}

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  twoFaCode?: Maybe<Scalars['String']>;
  twoFaType?: Maybe<TwoFaType>;
  userAgent?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export enum LookupType {
  EmailOtp = 'EMAIL_OTP',
  EmailVerification = 'EMAIL_VERIFICATION',
  MobileOtp = 'MOBILE_OTP',
  MobileVerification = 'MOBILE_VERIFICATION',
  ResetPassword = 'RESET_PASSWORD'
}

export type ManualWithdrawInput = {
  amount: Scalars['String'];
  bankAccountId: Scalars['String'];
  twoFaCode: Scalars['String'];
  twoFaType: TwoFaType;
};

export type Market = {
  __typename?: 'Market';
  binance24hrPrice?: Maybe<Price24Hr>;
  binanceExchangeInfo?: Maybe<ExchangeInfo>;
  channel: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fromGateway: Gateway;
  fromGatewayId: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  toGateway: Gateway;
  toGatewayId: Scalars['String'];
  type: MarketType;
  updatedAt: Scalars['DateTime'];
};

export type MarketPaginationResponse = {
  __typename?: 'MarketPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  markets: Array<Market>;
  offset: Scalars['Float'];
};

export enum MarketType {
  Binance = 'BINANCE'
}

export enum MerchantType {
  Binance = 'BINANCE',
  GateIo = 'GATE_IO',
  IrtGateways = 'IRT_GATEWAYS',
  Payeer = 'PAYEER',
  PerfectMoney = 'PERFECT_MONEY',
  WebMoney = 'WEB_MONEY'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateLoginWith2fa: Scalars['Boolean'];
  addBankAccount: BankAccount;
  addMessageToTicket: TicketMessages;
  adminAddMessageToTicket: TicketMessages;
  adminCreateTicket: Tickets;
  adminCreateUpdateAnnouncement: Announcement;
  adminCreateUpdateAppSlider: AppSlider;
  adminCreateUpdateBankGateway: BankGateway;
  adminCreateUpdateGateway: Gateway;
  adminCreateUpdateMarket: Market;
  adminCreateUpdateNotification: Notification;
  adminCreateUpdateRate: Rate;
  adminCreateUpdateSetting: Settings;
  adminCreateUpdateSupportAdmin: Admin;
  adminCreateUpdateUser: User;
  adminCreditDebitUserWallet: UserWallet;
  adminDeleteAnnouncements: Scalars['Boolean'];
  adminDeleteAppSliders: Scalars['Boolean'];
  adminDeleteBankAccounts: Scalars['Boolean'];
  adminDeleteBankGateways: Scalars['Boolean'];
  adminDeleteGateways: Scalars['Boolean'];
  adminDeleteMarkets: Scalars['Boolean'];
  adminDeleteNetworks: Scalars['Boolean'];
  adminDeleteNotifications: Scalars['Boolean'];
  adminDeleteRates: Scalars['Boolean'];
  adminDeleteSupportAdmin: Scalars['Boolean'];
  adminDeleteUserDocuments: Scalars['Boolean'];
  adminDeleteUserReviews: Scalars['Boolean'];
  adminDeleteUserWallets: Scalars['Boolean'];
  adminDeleteUsers: Scalars['Boolean'];
  adminLogin?: Maybe<AdminLoginResponse>;
  adminRejectDeposit: UserDeposit;
  adminSetSellBuyCompleted: Scalars['Boolean'];
  adminUpdateBankAccount: BankAccount;
  adminUpdateNetwork: Network;
  adminUpdateTicketStatus: Tickets;
  adminUpdateUserDocumentStatus: UserDocument;
  adminUpdateUserReview: UserReview;
  adminUpdateWithdrawal: Scalars['Boolean'];
  adminVerifyBankTransferDeposit: UserDeposit;
  cancelSpotTradeOrder: Trade;
  changePassword: Scalars['Boolean'];
  closeTicket: Tickets;
  confirmZibalDeposit: UserDeposit;
  createBankTransferDeposit: UserDeposit;
  createCryptoDeposit: UserDeposit;
  createLimitTradeOrder: Trade;
  createMarketTradeOrder: Trade;
  createOcoTradeOrders: Array<Trade>;
  createPayeerDeposit: CreatePayeerDepositResponse;
  createPerfectDeposit: CreatePerfectDepositResponse;
  createStopLimitTradeOrder: Trade;
  createTicket: Tickets;
  createWebmoneyDeposit: CreateWebmoneyDepositResponse;
  createZibalDeposit: CreateZibalDepositResponse;
  /** Jwt need to be verified for this mutation. AUTHENTICATOR is not an acceptable type for this mutation */
  cryptoWithdraw: UserWithdrawals;
  deactivateLoginWith2fa: Scalars['Boolean'];
  exchange: ExchangeResponse;
  /** Jwt need to be verified for this mutation. AUTHENTICATOR is not an acceptable type for this mutation */
  fiatWithdraw: UserWithdrawals;
  /** return otp auth url */
  initAuthenticator: Scalars['String'];
  login: LoginResponse;
  logout: Scalars['Boolean'];
  /** Jwt need to be verified for this mutation. AUTHENTICATOR is not an acceptable type for this mutation */
  manualWithdraw: UserWithdrawals;
  notificationsMarkAsSeen: Scalars['Boolean'];
  processCryptoDepositV2: UserDeposit;
  processPerfectVoucher: UserDeposit;
  /** return a new accessToken */
  refreshAccessToken: Scalars['String'];
  register: RegisterResponse;
  rejectDeposit: UserDeposit;
  resetPassword: Scalars['Boolean'];
  /** return retry date */
  sendLookup: Scalars['DateTime'];
  updateUser: User;
  uploadDocument: UserDocument;
  /** return a new verified accessToken */
  verifyAccessToken: Scalars['String'];
  verifyAuthenticator: Scalars['Boolean'];
  verifyEmail: Scalars['Boolean'];
  verifyMobileNumber: Scalars['Boolean'];
};

export type MutationAddBankAccountArgs = {
  input: CreateBankAccountInput;
};

export type MutationAddMessageToTicketArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: AddMessageToTicket;
};

export type MutationAdminAddMessageToTicketArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: AddMessageToTicket;
};

export type MutationAdminCreateTicketArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: AdminCreateTicketInput;
};

export type MutationAdminCreateUpdateAnnouncementArgs = {
  input: AdminCreateUpdateAnnouncementInput;
};

export type MutationAdminCreateUpdateAppSliderArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: AdminCreateUpdateAppSliderInput;
};

export type MutationAdminCreateUpdateBankGatewayArgs = {
  input: AdminCreateUpdateBankGatewayInput;
};

export type MutationAdminCreateUpdateGatewayArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: AdminCreateUpdateGatewayInput;
};

export type MutationAdminCreateUpdateMarketArgs = {
  input: AdminCreateUpdateMarketInput;
};

export type MutationAdminCreateUpdateNotificationArgs = {
  input: AdminCreateUpdateNotificationInput;
};

export type MutationAdminCreateUpdateRateArgs = {
  input: AdminCreateUpdateRateInput;
};

export type MutationAdminCreateUpdateSettingArgs = {
  input: AdminCreateUpdateSettingInput;
};

export type MutationAdminCreateUpdateSupportAdminArgs = {
  input: AdminCreateUpdateSupportAdminInput;
};

export type MutationAdminCreateUpdateUserArgs = {
  input: AdminCreateUpdateUserInput;
};

export type MutationAdminCreditDebitUserWalletArgs = {
  input: AdminCreditDebitUserWalletInput;
};

export type MutationAdminDeleteAnnouncementsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteAppSlidersArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteBankAccountsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteBankGatewaysArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteGatewaysArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteMarketsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteNetworksArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteNotificationsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteRatesArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteSupportAdminArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteUserDocumentsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteUserReviewsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteUserWalletsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminDeleteUsersArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};

export type MutationAdminRejectDepositArgs = {
  id: Scalars['String'];
};

export type MutationAdminSetSellBuyCompletedArgs = {
  convertId: Scalars['String'];
  side: TradeSide;
};

export type MutationAdminUpdateBankAccountArgs = {
  input: AdminUpdateBankAccountInput;
};

export type MutationAdminUpdateNetworkArgs = {
  input: AdminCreateUpdateNetworkInput;
};

export type MutationAdminUpdateTicketStatusArgs = {
  input: AdminUpdateTicketStatusInput;
};

export type MutationAdminUpdateUserDocumentStatusArgs = {
  input: AdminUpdateUserDocumentStatusInput;
};

export type MutationAdminUpdateUserReviewArgs = {
  input: AdminUpdateUserReviewInput;
};

export type MutationAdminUpdateWithdrawalArgs = {
  input: UpdateWithdrawInput;
};

export type MutationAdminVerifyBankTransferDepositArgs = {
  id: Scalars['String'];
};

export type MutationCancelSpotTradeOrderArgs = {
  id: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCloseTicketArgs = {
  id: Scalars['String'];
};

export type MutationConfirmZibalDepositArgs = {
  id: Scalars['String'];
};

export type MutationCreateBankTransferDepositArgs = {
  input: CreateBankTransferDepositInput;
};

export type MutationCreateCryptoDepositArgs = {
  input: CreateCryptoDepositInput;
};

export type MutationCreateLimitTradeOrderArgs = {
  input: CreateLimitTradeOrderInput;
};

export type MutationCreateMarketTradeOrderArgs = {
  input: CreateMarketTradeOrderInput;
};

export type MutationCreateOcoTradeOrdersArgs = {
  input: CreateOcoTradeOrderInput;
};

export type MutationCreatePayeerDepositArgs = {
  input: CreateNonCryptoDepositInput;
};

export type MutationCreatePerfectDepositArgs = {
  input: CreateNonCryptoDepositInput;
};

export type MutationCreateStopLimitTradeOrderArgs = {
  input: CreateStopLimitTradeOrderInput;
};

export type MutationCreateTicketArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: CreateTicketInput;
};

export type MutationCreateWebmoneyDepositArgs = {
  input: CreateNonCryptoDepositInput;
};

export type MutationCreateZibalDepositArgs = {
  input: CreateZibalDepositInput;
};

export type MutationCryptoWithdrawArgs = {
  input: CryptoWithdrawInput;
};

export type MutationDeactivateLoginWith2faArgs = {
  input: TwoFaInput;
};

export type MutationExchangeArgs = {
  priceKey: Scalars['String'];
  sendAmount: Scalars['String'];
};

export type MutationFiatWithdrawArgs = {
  input: FiatWithdrawInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLogoutArgs = {
  refreshToken: Scalars['String'];
};

export type MutationManualWithdrawArgs = {
  input: ManualWithdrawInput;
};

export type MutationNotificationsMarkAsSeenArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationProcessCryptoDepositV2Args = {
  input: ProcessCryptoDepositInputV2;
};

export type MutationProcessPerfectVoucherArgs = {
  input: ProcessPerfectVoucherInput;
};

export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationRejectDepositArgs = {
  id: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSendLookupArgs = {
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  recaptcha: Scalars['String'];
  type: LookupType;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUploadDocumentArgs = {
  file: Scalars['Upload'];
  input: CreateUserDocumentInput;
};

export type MutationVerifyAccessTokenArgs = {
  code: Scalars['String'];
};

export type MutationVerifyAuthenticatorArgs = {
  otp: Scalars['String'];
};

export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type MutationVerifyMobileNumberArgs = {
  input: VerifyMobileNumberInput;
};

export type MyDepositsFilterInput = {
  gatewayId?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
  status?: Maybe<DepositStatus>;
};

export type MyGatewaysFilterInput = {
  nameOrCurrency?: Maybe<Scalars['String']>;
  onlyPositiveBalances?: Maybe<Scalars['Boolean']>;
  onlyZeroBalances?: Maybe<Scalars['Boolean']>;
};

export type MyTradesFilterInput = {
  marketId?: Maybe<Scalars['String']>;
  status?: Maybe<TradeStatus>;
};

export type MyWithdrawalsFilterInput = {
  gatewayId?: Maybe<Scalars['String']>;
};

export type Network = {
  __typename?: 'Network';
  addressRegex: Scalars['String'];
  createdAt: Scalars['DateTime'];
  depositAddress?: Maybe<DepositAddress>;
  depositEnable: Scalars['Boolean'];
  fee: Scalars['String'];
  gateway: Gateway;
  gatewayId: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  network: Scalars['String'];
  networkName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  withdrawEnable: Scalars['Boolean'];
  withdrawMin: Scalars['String'];
};

export type NetworkPaginationResponse = {
  __typename?: 'NetworkPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  networks: Array<Network>;
  offset: Scalars['Float'];
};

export type Notification = {
  __typename?: 'Notification';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  seenAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type NotificationPaginationResponse = {
  __typename?: 'NotificationPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  notifications: Array<Notification>;
  offset: Scalars['Float'];
};

export type Price24Hr = {
  __typename?: 'Price24HR';
  lastPrice: Scalars['String'];
  priceChange: Scalars['String'];
  priceChangePercent: Scalars['String'];
};

export type ProcessCryptoDepositInputV2 = {
  depositId: Scalars['String'];
  txId: Scalars['String'];
};

export type ProcessPerfectVoucherInput = {
  gatewayId: Scalars['String'];
  voucherCode: Scalars['String'];
  voucherNumber: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  adminGetAllGatewaysWithWallet: Array<GatewaysWithWallet>;
  adminGetAnnouncement: Announcement;
  adminGetAnnouncements: AnnouncementPaginationResponse;
  adminGetAppSlider: AppSlider;
  adminGetAppSliders: AppSliderPaginationResponse;
  adminGetBankAccount: BankAccount;
  adminGetBankAccounts: BankAccountPaginationResponse;
  adminGetBankGateway: BankGateway;
  adminGetBankGateways: BankGatewayPaginationResponse;
  adminGetConvert: Convert;
  adminGetConverts: ConvertPaginationResponse;
  adminGetGateway: Gateway;
  adminGetGateways: GatewayPaginationResponse;
  adminGetMarket: Market;
  adminGetMarkets: MarketPaginationResponse;
  adminGetNetwork: Network;
  adminGetNetworks: NetworkPaginationResponse;
  adminGetNotification: Notification;
  adminGetNotifications: NotificationPaginationResponse;
  adminGetRate: Rate;
  adminGetRates: RatePaginationResponse;
  adminGetSettings?: Maybe<Settings>;
  adminGetSupportAdmin: Admin;
  adminGetSupportAdmins: SupportsPaginationResponse;
  adminGetTicket: Tickets;
  adminGetTickets: TicketPaginationResponse;
  adminGetTrade: Trade;
  adminGetTrades: TradePaginationResponse;
  adminGetTransaction: Transaction;
  adminGetTransactions: TransactionPaginationResponse;
  adminGetUser: User;
  adminGetUserDeposit: UserDeposit;
  adminGetUserDeposits: UserDepositPaginationResponse;
  adminGetUserDocument: UserDocument;
  adminGetUserDocuments: UserDocumentPaginationResponse;
  adminGetUserReview: UserReview;
  adminGetUserReviews: UserReviewPaginationResponse;
  adminGetUserWallet: UserWallet;
  adminGetUserWalletHistories: UserWalletHistoryPaginationResponse;
  adminGetUserWalletHistory: UserWalletHistory;
  adminGetUserWallets: UserWalletPaginationResponse;
  adminGetUserWithdrawal: UserWithdrawals;
  adminGetUserWithdrawals: UserWithdrawalPaginationResponse;
  adminGetUsers: UserPaginationResponse;
  adminMe: Admin;
  binanceSpotAssetBalances?: Maybe<Array<BinanceSpotAssetBalance>>;
  getAnnouncement: Announcement;
  getAnnouncements: AnnouncementPaginationResponse;
  getAppSliders: AppSliderPaginationResponse;
  getBankTransferGateways: Array<BankGateway>;
  getCryptoDepositAddress: DepositAddress;
  getDepthStreams: Array<BinanceDepth>;
  getGateway: Gateway;
  getGateways: GatewayPaginationResponse;
  getMarkets: MarketPaginationResponse;
  getMyTicket: Tickets;
  getMyTickets: TicketPaginationResponse;
  getRates: RatePaginationResponse;
  getStaticRate: StaticPrice;
  getTradeStreams: Array<BinanceTrade>;
  getTwoFaMethods: Array<TwoFaMethod>;
  /** set 'orderBy' to "RAND" to get random values */
  getUserReviews: UserReviewPaginationResponse;
  /** This is a private query and returns the current user object */
  me: User;
  myBankAccounts: BankAccountPaginationResponse;
  myConverts: ConvertPaginationResponse;
  myDeposit: UserDeposit;
  myDeposits: UserDepositPaginationResponse;
  myDocument: UserDocument;
  myDocuments: UserDocumentPaginationResponse;
  myGateway?: Maybe<Gateway>;
  myGateways: GatewayPaginationResponse;
  myGetRates: RatePaginationResponse;
  myMarkets: MarketPaginationResponse;
  myNotifications: NotificationPaginationResponse;
  myReviews: UserReviewPaginationResponse;
  myTrades: TradePaginationResponse;
  myTransactions: TransactionPaginationResponse;
  myUserWalletHistories: UserWalletHistoryPaginationResponse;
  myUserWalletHistory: UserWalletHistory;
  myWallets: UserWalletPaginationResponse;
  myWithdrawal: UserWithdrawals;
  myWithdrawals: UserWithdrawalPaginationResponse;
};

export type QueryAdminGetAnnouncementArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetAnnouncementsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetAppSliderArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetAppSlidersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetBankAccountArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetBankAccountsArgs = {
  filter?: Maybe<AdminGetBankAccountsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetBankGatewayArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetBankGatewaysArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetConvertArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetConvertsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetGatewayArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetGatewaysArgs = {
  filter?: Maybe<GetGatewaysFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetMarketArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetMarketsArgs = {
  filter?: Maybe<GetMarketsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetNetworkArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetNetworksArgs = {
  filter?: Maybe<AdminGetNetworksFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetNotificationArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetRateArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetRatesArgs = {
  filter?: Maybe<AdminGetRatesFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetSupportAdminArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetSupportAdminsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetTicketArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetTicketsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetTradeArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetTradesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetTransactionArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetTransactionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetUserArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetUserDepositArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetUserDepositsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetUserDocumentArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetUserDocumentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetUserReviewArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetUserReviewsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetUserWalletArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetUserWalletHistoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetUserWalletHistoryArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetUserWalletsArgs = {
  filter?: Maybe<AdminGetUserWalletsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetUserWithdrawalArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetUserWithdrawalsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryAdminGetUsersArgs = {
  filter?: Maybe<AdminGetUsersFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryGetAnnouncementArgs = {
  id: Scalars['String'];
};

export type QueryGetAnnouncementsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryGetAppSlidersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryGetCryptoDepositAddressArgs = {
  gatewayId?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
};

export type QueryGetDepthStreamsArgs = {
  channel: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetGatewayArgs = {
  id: Scalars['String'];
};

export type QueryGetGatewaysArgs = {
  filter?: Maybe<GetGatewaysFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryGetMarketsArgs = {
  filter?: Maybe<GetMarketsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryGetMyTicketArgs = {
  id: Scalars['String'];
};

export type QueryGetMyTicketsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryGetRatesArgs = {
  filter?: Maybe<GetRatesFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryGetStaticRateArgs = {
  input: GetStaticPriceInput;
};

export type QueryGetTradeStreamsArgs = {
  channel: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetTwoFaMethodsArgs = {
  input: GetTwoFaMethodsInput;
};

export type QueryGetUserReviewsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyBankAccountsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyConvertsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyDepositArgs = {
  id: Scalars['String'];
};

export type QueryMyDepositsArgs = {
  filter?: Maybe<MyDepositsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyDocumentArgs = {
  id: Scalars['String'];
};

export type QueryMyDocumentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyGatewayArgs = {
  id: Scalars['String'];
};

export type QueryMyGatewaysArgs = {
  filter?: Maybe<MyGatewaysFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyGetRatesArgs = {
  filter?: Maybe<GetRatesFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyMarketsArgs = {
  filter?: Maybe<GetMarketsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyReviewsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyTradesArgs = {
  filter?: Maybe<MyTradesFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyTransactionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyUserWalletHistoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyUserWalletHistoryArgs = {
  id: Scalars['String'];
};

export type QueryMyWalletsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type QueryMyWithdrawalArgs = {
  id: Scalars['String'];
};

export type QueryMyWithdrawalsArgs = {
  filter?: Maybe<MyWithdrawalsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type Rate = {
  __typename?: 'Rate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isAuto: Scalars['Boolean'];
  myReceiveGateway: Gateway;
  mySendGateway: Gateway;
  rateFrom?: Maybe<Scalars['String']>;
  ratePercent?: Maybe<Scalars['Float']>;
  rateTo?: Maybe<Scalars['String']>;
  receiveGateway: Gateway;
  receiveGatewayId: Scalars['String'];
  sendGateway: Gateway;
  sendGatewayId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RateLimit = {
  __typename?: 'RateLimit';
  interval: Scalars['String'];
  intervalNum: Scalars['Float'];
  limit: Scalars['Float'];
  rateLimitType: Scalars['String'];
};

export type RatePaginationResponse = {
  __typename?: 'RatePaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  rates: Array<Rate>;
};

export type RegisterInput = {
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export type ResetPasswordInput = {
  code: Scalars['String'];
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  userId: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  adminMobile?: Maybe<Scalars['String']>;
  bronzeDailyWithdraw: Scalars['Float'];
  bronzeTradeCommission: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  /** Dynamic order validation window in hours */
  dynamicOrderWindow?: Maybe<Scalars['Float']>;
  goldDailyWithdraw: Scalars['Float'];
  goldTradeCommission: Scalars['Float'];
  id: Scalars['ID'];
  /** None crypto order validation window in minutes */
  nonCryptoOrderWindow?: Maybe<Scalars['Float']>;
  normalDailyWithdraw: Scalars['Float'];
  normalTradeCommission: Scalars['Float'];
  referralCommission?: Maybe<Scalars['String']>;
  silverDailyWithdraw: Scalars['Float'];
  silverTradeCommission: Scalars['Float'];
  /** Static order validation window in minutes */
  staticOrderWindow?: Maybe<Scalars['Float']>;
  /** Static price validation window in seconds */
  staticPriceWindow?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  usdPrice?: Maybe<Scalars['String']>;
  vipDailyWithdraw: Scalars['Float'];
  vipTradeCommission: Scalars['Float'];
};

export enum SliderType {
  DashboardSlider = 'DASHBOARD_SLIDER',
  HomeSlider = 'HOME_SLIDER'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StaticPrice = {
  __typename?: 'StaticPrice';
  expiresIn: Scalars['Int'];
  priceKey: Scalars['ID'];
  receiveGateway: Gateway;
  receiveRate: Scalars['String'];
  sendGateway: Gateway;
  sendRate: Scalars['String'];
};

export type SupportsPaginationResponse = {
  __typename?: 'SupportsPaginationResponse';
  admins: Array<Admin>;
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
};

export type TicketMessages = {
  __typename?: 'TicketMessages';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fileLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type TicketPaginationResponse = {
  __typename?: 'TicketPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  tickets: Array<Tickets>;
};

export enum TicketStatus {
  Answered = 'ANSWERED',
  Closed = 'CLOSED',
  Pending = 'PENDING'
}

export type Tickets = {
  __typename?: 'Tickets';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messages: Array<TicketMessages>;
  reference: Scalars['String'];
  status: TicketStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type Trade = {
  __typename?: 'Trade';
  amount?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  market: Market;
  marketId: Scalars['String'];
  orderId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  reference: Scalars['String'];
  side: TradeSide;
  status: TradeStatus;
  stop?: Maybe<Scalars['Float']>;
  type: TradeType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type TradePaginationResponse = {
  __typename?: 'TradePaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  trades: Array<Trade>;
};

export enum TradeSide {
  Buy = 'BUY',
  Sell = 'SELL'
}

export enum TradeStatus {
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Filled = 'FILLED',
  New = 'NEW',
  PartiallyFilled = 'PARTIALLY_FILLED',
  PendingCancel = 'PENDING_CANCEL',
  Rejected = 'REJECTED'
}

export enum TradeType {
  Limit = 'LIMIT',
  Market = 'MARKET',
  Oco = 'OCO',
  StopLimit = 'STOP_LIMIT'
}

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['String'];
  bankGatewayId?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  convertId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  incremental: Scalars['Boolean'];
  merchantName: Scalars['String'];
  status: TransactionStatus;
  tradeId?: Maybe<Scalars['String']>;
  txId?: Maybe<Scalars['String']>;
  type: TransactionType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userDepositId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userWithdrawalId?: Maybe<Scalars['String']>;
};

export type TransactionPaginationResponse = {
  __typename?: 'TransactionPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  transactions: Array<Transaction>;
};

export enum TransactionStatus {
  Pending = 'PENDING',
  Successful = 'SUCCESSFUL',
  Unsuccessful = 'UNSUCCESSFUL'
}

export enum TransactionType {
  Commission = 'COMMISSION',
  Convert = 'CONVERT',
  Deposit = 'DEPOSIT',
  InternalTransfer = 'INTERNAL_TRANSFER',
  Trade = 'TRADE',
  Withdraw = 'WITHDRAW'
}

export type TwoFaInput = {
  twoFaCode: Scalars['String'];
  twoFaType: TwoFaType;
};

export type TwoFaMethod = {
  __typename?: 'TwoFaMethod';
  type: TwoFaType;
  value?: Maybe<Scalars['String']>;
};

export enum TwoFaType {
  Authenticator = 'AUTHENTICATOR',
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type UpdateUserInput = {
  address?: Maybe<Scalars['String']>;
  birthCertificateCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fatherName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  nationalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UpdateWithdrawInput = {
  id: Scalars['String'];
  status: WithdrawalStatus;
  txId?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']>;
  authenticatorActivated: Scalars['Boolean'];
  bankAccounts: BankAccountPaginationResponse;
  birthCertificateCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  documents: UserDocumentPaginationResponse;
  email: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  fatherName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  invitedUsers: Array<InvitedUser>;
  ipAddress: Scalars['String'];
  lastName: Scalars['String'];
  loginWithOtpActivated: Scalars['Boolean'];
  mobileNumber?: Maybe<Scalars['String']>;
  mobileVerifiedAt?: Maybe<Scalars['DateTime']>;
  nationalCode?: Maybe<Scalars['String']>;
  notifications: NotificationPaginationResponse;
  otpSecret?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referralCode: Scalars['String'];
  reviews: UserReviewPaginationResponse;
  sessions: Array<UserRefreshToken>;
  status: UserStatus;
  trades: TradePaginationResponse;
  transactions: TransactionPaginationResponse;
  updatedAt: Scalars['DateTime'];
  walletHistories: UserWalletHistoryPaginationResponse;
  wallets: UserWalletPaginationResponse;
};

export type UserBankAccountsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserDocumentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserReviewsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserTradesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserTransactionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserWalletHistoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserWalletsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserDeposit = {
  __typename?: 'UserDeposit';
  amount: Scalars['String'];
  bankGateway?: Maybe<BankGateway>;
  bankGatewayId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  gateway?: Maybe<Gateway>;
  gatewayId: Scalars['String'];
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  network?: Maybe<Network>;
  networkId?: Maybe<Scalars['String']>;
  reference: Scalars['String'];
  sentUserId?: Maybe<Scalars['String']>;
  sourceAddress?: Maybe<Scalars['String']>;
  status: DepositStatus;
  txId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type UserDepositPaginationResponse = {
  __typename?: 'UserDepositPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  userdeposits: Array<UserDeposit>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  createdAt: Scalars['DateTime'];
  fileLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: DocumentStatus;
  type: DocumentType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type UserDocumentPaginationResponse = {
  __typename?: 'UserDocumentPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  userdocuments: Array<UserDocument>;
};

export type UserPaginationResponse = {
  __typename?: 'UserPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  users: Array<User>;
};

export type UserRefreshToken = {
  __typename?: 'UserRefreshToken';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  isActive: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  userAgent?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type UserReview = {
  __typename?: 'UserReview';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isApproved?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId?: Maybe<Scalars['String']>;
};

export type UserReviewPaginationResponse = {
  __typename?: 'UserReviewPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  userreviews: Array<UserReview>;
};

export enum UserStatus {
  Blocked = 'BLOCKED',
  Bronze = 'BRONZE',
  Gold = 'GOLD',
  Registered = 'REGISTERED',
  Silver = 'SILVER',
  Verified = 'VERIFIED',
  Vip = 'VIP'
}

export type UserWallet = {
  __typename?: 'UserWallet';
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  gateway: Gateway;
  gatewayId: Scalars['String'];
  id: Scalars['ID'];
  myUserWalletHistories: UserWalletHistoryPaginationResponse;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type UserWalletMyUserWalletHistoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
};

export type UserWalletHistory = {
  __typename?: 'UserWalletHistory';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  commissionerUser?: Maybe<User>;
  commissionerUserId?: Maybe<Scalars['String']>;
  convert?: Maybe<Convert>;
  convertId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  credited: Scalars['Boolean'];
  id: Scalars['ID'];
  trade?: Maybe<Trade>;
  tradeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userDeposit?: Maybe<UserDeposit>;
  userDepositId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userWallet: UserWallet;
  userWalletId: Scalars['String'];
  userWithdrawal?: Maybe<UserWithdrawals>;
  userWithdrawalId?: Maybe<Scalars['String']>;
};

export type UserWalletHistoryPaginationResponse = {
  __typename?: 'UserWalletHistoryPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  userwallethistorys: Array<UserWalletHistory>;
};

export type UserWalletPaginationResponse = {
  __typename?: 'UserWalletPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  userwallets: Array<UserWallet>;
};

export type UserWithdrawalPaginationResponse = {
  __typename?: 'UserWithdrawalPaginationResponse';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Float']>;
  offset: Scalars['Float'];
  userwithdrawals: Array<UserWithdrawals>;
};

export type UserWithdrawals = {
  __typename?: 'UserWithdrawals';
  amount: Scalars['String'];
  bankAccount?: Maybe<BankAccount>;
  bankAccountId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  destinationAddress?: Maybe<Scalars['String']>;
  destinationUser: Scalars['String'];
  gateway?: Maybe<Gateway>;
  gatewayId: Scalars['String'];
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  network?: Maybe<Network>;
  networkFee?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
  receivedUser?: Maybe<User>;
  receivedUserId?: Maybe<Scalars['String']>;
  reference: Scalars['String'];
  status?: Maybe<WithdrawalStatus>;
  txId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  voucherDetail?: Maybe<Scalars['String']>;
};

export type VerifyEmailInput = {
  code: Scalars['String'];
  recaptcha: Scalars['String'];
  userId: Scalars['String'];
};

export type VerifyMobileNumberInput = {
  code: Scalars['String'];
};

export enum WithdrawalStatus {
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Successful = 'SUCCESSFUL'
}

export type SupportAdminFragmentFragment = {
  __typename?: 'Admin';
  id: string;
  createdAt: any;
  firstName: string;
  lastName: string;
  email: string;
  role: IRoles;
};

export type GetSupportAdminsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetSupportAdminsQuery = {
  __typename?: 'Query';
  adminGetSupportAdmins: {
    __typename?: 'SupportsPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    admins: Array<{
      __typename?: 'Admin';
      id: string;
      createdAt: any;
      firstName: string;
      lastName: string;
      email: string;
      role: IRoles;
    }>;
  };
};

export type GetSupportAdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSupportAdminQuery = {
  __typename?: 'Query';
  adminGetSupportAdmin: {
    __typename?: 'Admin';
    id: string;
    createdAt: any;
    firstName: string;
    lastName: string;
    email: string;
    role: IRoles;
  };
};

export type CreateUpdateSupportAdminMutationVariables = Exact<{
  input: AdminCreateUpdateSupportAdminInput;
}>;

export type CreateUpdateSupportAdminMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateSupportAdmin: {
    __typename?: 'Admin';
    id: string;
    createdAt: any;
    firstName: string;
    lastName: string;
    email: string;
    role: IRoles;
  };
};

export type DeleteSupportAdminsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteSupportAdminsMutation = {
  __typename?: 'Mutation';
  adminDeleteSupportAdmin: boolean;
};

export type AnnouncementFragmentFragment = {
  __typename?: 'Announcement';
  id: string;
  createdAt: any;
  updatedAt: any;
  title: string;
  content: string;
  lang: Languages;
};

export type ListAnnouncementFragmentFragment = {
  __typename?: 'Announcement';
  id: string;
  createdAt: any;
  updatedAt: any;
  title: string;
  lang: Languages;
};

export type GetAnnouncementsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetAnnouncementsQuery = {
  __typename?: 'Query';
  adminGetAnnouncements: {
    __typename?: 'AnnouncementPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    announcements: Array<{
      __typename?: 'Announcement';
      id: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      lang: Languages;
    }>;
  };
};

export type GetAnnouncementQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetAnnouncementQuery = {
  __typename?: 'Query';
  adminGetAnnouncement: {
    __typename?: 'Announcement';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    content: string;
    lang: Languages;
  };
};

export type CreateUpdateAnnouncementMutationVariables = Exact<{
  input: AdminCreateUpdateAnnouncementInput;
}>;

export type CreateUpdateAnnouncementMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateAnnouncement: {
    __typename?: 'Announcement';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    content: string;
    lang: Languages;
  };
};

export type DeleteAnnouncementsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteAnnouncementsMutation = {
  __typename?: 'Mutation';
  adminDeleteAnnouncements: boolean;
};

export type AppSliderFragmentFragment = {
  __typename?: 'AppSlider';
  id: string;
  createdAt: any;
  updatedAt: any;
  imageLink: string;
  name?: string | null | undefined;
  type: SliderType;
  lang: Languages;
};

export type ListAppSliderFragmentFragment = {
  __typename?: 'AppSlider';
  id: string;
  createdAt: any;
  updatedAt: any;
  imageLink: string;
  name?: string | null | undefined;
  type: SliderType;
  lang: Languages;
};

export type GetAppSlidersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetAppSlidersQuery = {
  __typename?: 'Query';
  adminGetAppSliders: {
    __typename?: 'AppSliderPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    appsliders: Array<{
      __typename?: 'AppSlider';
      id: string;
      createdAt: any;
      updatedAt: any;
      imageLink: string;
      name?: string | null | undefined;
      type: SliderType;
      lang: Languages;
    }>;
  };
};

export type GetAppSliderQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetAppSliderQuery = {
  __typename?: 'Query';
  adminGetAppSlider: {
    __typename?: 'AppSlider';
    id: string;
    createdAt: any;
    updatedAt: any;
    imageLink: string;
    name?: string | null | undefined;
    type: SliderType;
    lang: Languages;
  };
};

export type CreateUpdateAppSliderMutationVariables = Exact<{
  input: AdminCreateUpdateAppSliderInput;
  file?: Maybe<Scalars['Upload']>;
}>;

export type CreateUpdateAppSliderMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateAppSlider: {
    __typename?: 'AppSlider';
    id: string;
    createdAt: any;
    updatedAt: any;
    imageLink: string;
    name?: string | null | undefined;
    type: SliderType;
    lang: Languages;
  };
};

export type DeleteAppSlidersMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteAppSlidersMutation = { __typename?: 'Mutation'; adminDeleteAppSliders: boolean };

export type AdminFragmentFragment = {
  __typename?: 'Admin';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type AdminLoginMutationVariables = Exact<{
  input: AdminLoginInput;
}>;

export type AdminLoginMutation = {
  __typename?: 'Mutation';
  adminLogin?:
    | {
        __typename?: 'AdminLoginResponse';
        accessToken?: string | null | undefined;
        otpAuthUrl?: string | null | undefined;
        admin?:
          | { __typename?: 'Admin'; id: string; firstName: string; lastName: string; email: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AdminDetailQueryVariables = Exact<{ [key: string]: never }>;

export type AdminDetailQuery = {
  __typename?: 'Query';
  adminMe: { __typename?: 'Admin'; id: string; firstName: string; lastName: string; email: string };
};

export type GatewayBankFragmentFragment = {
  __typename?: 'BankGateway';
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  persianName: string;
  isActive?: boolean | null | undefined;
  type: BankGatewayType;
  merchantId?: string | null | undefined;
  destinationCartNumber?: string | null | undefined;
  destinationIban?: string | null | undefined;
  destinationName?: string | null | undefined;
};

export type GetBankGatewaysQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetBankGatewaysQuery = {
  __typename?: 'Query';
  adminGetBankGateways: {
    __typename?: 'BankGatewayPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    bankgateways: Array<{
      __typename?: 'BankGateway';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      persianName: string;
      isActive?: boolean | null | undefined;
      type: BankGatewayType;
      merchantId?: string | null | undefined;
      destinationCartNumber?: string | null | undefined;
      destinationIban?: string | null | undefined;
      destinationName?: string | null | undefined;
    }>;
  };
};

export type GetBankGatewayQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetBankGatewayQuery = {
  __typename?: 'Query';
  adminGetBankGateway: {
    __typename?: 'BankGateway';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    persianName: string;
    isActive?: boolean | null | undefined;
    type: BankGatewayType;
    merchantId?: string | null | undefined;
    destinationCartNumber?: string | null | undefined;
    destinationIban?: string | null | undefined;
    destinationName?: string | null | undefined;
  };
};

export type CreateUpdateBankGatewayMutationVariables = Exact<{
  input: AdminCreateUpdateBankGatewayInput;
}>;

export type CreateUpdateBankGatewayMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateBankGateway: {
    __typename?: 'BankGateway';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    persianName: string;
    isActive?: boolean | null | undefined;
    type: BankGatewayType;
    merchantId?: string | null | undefined;
    destinationCartNumber?: string | null | undefined;
    destinationIban?: string | null | undefined;
    destinationName?: string | null | undefined;
  };
};

export type DeleteBankGatewaysMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteBankGatewaysMutation = {
  __typename?: 'Mutation';
  adminDeleteBankGateways: boolean;
};

export type ListDepositFragmentFragment = {
  __typename?: 'UserDeposit';
  id: string;
  reference: string;
  amount: string;
  currency: string;
  status: DepositStatus;
  createdAt: any;
  updatedAt: any;
  gateway?: { __typename?: 'Gateway'; name: string; persianName: string } | null | undefined;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type DepositFragmentFragment = {
  __typename?: 'UserDeposit';
  id: string;
  reference: string;
  amount: string;
  currency: string;
  status: DepositStatus;
  txId?: string | null | undefined;
  sourceAddress?: string | null | undefined;
  ipAddress: string;
  description?: string | null | undefined;
  createdAt: any;
  updatedAt: any;
  gateway?:
    | { __typename?: 'Gateway'; name: string; persianName: string; merchant: MerchantType }
    | null
    | undefined;
  network?: { __typename?: 'Network'; networkName: string } | null | undefined;
  bankGateway?: { __typename?: 'BankGateway'; type: BankGatewayType } | null | undefined;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type GetDepositsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetDepositsQuery = {
  __typename?: 'Query';
  adminGetUserDeposits: {
    __typename?: 'UserDepositPaginationResponse';
    limit?: number | null | undefined;
    count: number;
    offset: number;
    userdeposits: Array<{
      __typename?: 'UserDeposit';
      id: string;
      reference: string;
      amount: string;
      currency: string;
      status: DepositStatus;
      createdAt: any;
      updatedAt: any;
      gateway?: { __typename?: 'Gateway'; name: string; persianName: string } | null | undefined;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    }>;
  };
};

export type GetDepositQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetDepositQuery = {
  __typename?: 'Query';
  adminGetUserDeposit: {
    __typename?: 'UserDeposit';
    id: string;
    reference: string;
    amount: string;
    currency: string;
    status: DepositStatus;
    txId?: string | null | undefined;
    sourceAddress?: string | null | undefined;
    ipAddress: string;
    description?: string | null | undefined;
    createdAt: any;
    updatedAt: any;
    gateway?:
      | { __typename?: 'Gateway'; name: string; persianName: string; merchant: MerchantType }
      | null
      | undefined;
    network?: { __typename?: 'Network'; networkName: string } | null | undefined;
    bankGateway?: { __typename?: 'BankGateway'; type: BankGatewayType } | null | undefined;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type VerifyBankTransferDepositMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type VerifyBankTransferDepositMutation = {
  __typename?: 'Mutation';
  adminVerifyBankTransferDeposit: {
    __typename?: 'UserDeposit';
    id: string;
    reference: string;
    amount: string;
    currency: string;
    status: DepositStatus;
    createdAt: any;
    updatedAt: any;
    gateway?: { __typename?: 'Gateway'; name: string; persianName: string } | null | undefined;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type RejectDepositMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RejectDepositMutation = {
  __typename?: 'Mutation';
  adminRejectDeposit: {
    __typename?: 'UserDeposit';
    id: string;
    reference: string;
    amount: string;
    currency: string;
    status: DepositStatus;
    createdAt: any;
    updatedAt: any;
    gateway?: { __typename?: 'Gateway'; name: string; persianName: string } | null | undefined;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type ListExchangeFragmentFragment = {
  __typename?: 'Convert';
  id: string;
  reference: string;
  sendAmount: string;
  receiveAmount: string;
  buyCompleted?: boolean | null | undefined;
  sellCompleted?: boolean | null | undefined;
  createdAt: any;
  updatedAt: any;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  sendGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
  receiveGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
};

export type ExchangeFragmentFragment = {
  __typename?: 'Convert';
  id: string;
  reference: string;
  sendAmount: string;
  receiveAmount: string;
  sendRate: string;
  receiveRate: string;
  buyCompleted?: boolean | null | undefined;
  sellCompleted?: boolean | null | undefined;
  commission?: string | null | undefined;
  ipAddress: string;
  createdAt: any;
  updatedAt: any;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  sendGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
  receiveGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
};

export type GetExchangesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetExchangesQuery = {
  __typename?: 'Query';
  adminGetConverts: {
    __typename?: 'ConvertPaginationResponse';
    limit?: number | null | undefined;
    count: number;
    offset: number;
    converts: Array<{
      __typename?: 'Convert';
      id: string;
      reference: string;
      sendAmount: string;
      receiveAmount: string;
      buyCompleted?: boolean | null | undefined;
      sellCompleted?: boolean | null | undefined;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
      sendGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
      receiveGateway: {
        __typename?: 'Gateway';
        name: string;
        persianName: string;
        currency: string;
      };
    }>;
  };
};

export type GetExchangeQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetExchangeQuery = {
  __typename?: 'Query';
  adminGetConvert: {
    __typename?: 'Convert';
    id: string;
    reference: string;
    sendAmount: string;
    receiveAmount: string;
    sendRate: string;
    receiveRate: string;
    buyCompleted?: boolean | null | undefined;
    sellCompleted?: boolean | null | undefined;
    commission?: string | null | undefined;
    ipAddress: string;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    sendGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
    receiveGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
  };
};

export type SetSellBuyCompletedMutationVariables = Exact<{
  convertId: Scalars['String'];
  side: TradeSide;
}>;

export type SetSellBuyCompletedMutation = {
  __typename?: 'Mutation';
  adminSetSellBuyCompleted: boolean;
};

export type GatewayFragmentFragment = {
  __typename?: 'Gateway';
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  persianName: string;
  currency: string;
  minAmount: string;
  maxAmount: string;
  reserve?: string | null | undefined;
  sortOrder?: number | null | undefined;
  bestCode?: string | null | undefined;
  isCrypto: boolean;
  allowSend: boolean;
  isAuto: boolean;
  icon?: string | null | undefined;
  isActive: boolean;
  merchant: MerchantType;
  field_1?: string | null | undefined;
};

export type ListGatewayFragmentFragment = {
  __typename?: 'Gateway';
  id: string;
  createdAt: any;
  name: string;
  persianName: string;
  currency: string;
  isActive: boolean;
};

export type GetGatewaysQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<GetGatewaysFilterInput>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetGatewaysQuery = {
  __typename?: 'Query';
  adminGetGateways: {
    __typename?: 'GatewayPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    gateways: Array<{
      __typename?: 'Gateway';
      id: string;
      createdAt: any;
      name: string;
      persianName: string;
      currency: string;
      isActive: boolean;
    }>;
  };
};

export type GetGatewayQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetGatewayQuery = {
  __typename?: 'Query';
  adminGetGateway: {
    __typename?: 'Gateway';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    persianName: string;
    currency: string;
    minAmount: string;
    maxAmount: string;
    reserve?: string | null | undefined;
    sortOrder?: number | null | undefined;
    bestCode?: string | null | undefined;
    isCrypto: boolean;
    allowSend: boolean;
    isAuto: boolean;
    icon?: string | null | undefined;
    isActive: boolean;
    merchant: MerchantType;
    field_1?: string | null | undefined;
  };
};

export type CreateUpdateGatewayMutationVariables = Exact<{
  input: AdminCreateUpdateGatewayInput;
  file?: Maybe<Scalars['Upload']>;
}>;

export type CreateUpdateGatewayMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateGateway: {
    __typename?: 'Gateway';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    persianName: string;
    currency: string;
    minAmount: string;
    maxAmount: string;
    reserve?: string | null | undefined;
    sortOrder?: number | null | undefined;
    bestCode?: string | null | undefined;
    isCrypto: boolean;
    allowSend: boolean;
    isAuto: boolean;
    icon?: string | null | undefined;
    isActive: boolean;
    merchant: MerchantType;
    field_1?: string | null | undefined;
  };
};

export type DeleteGatewaysMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteGatewaysMutation = { __typename?: 'Mutation'; adminDeleteGateways: boolean };

export type GetMarketsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<GetMarketsFilterInput>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetMarketsQuery = {
  __typename?: 'Query';
  adminGetMarkets: {
    __typename?: 'MarketPaginationResponse';
    limit?: number | null | undefined;
    count: number;
    offset: number;
    markets: Array<{
      __typename?: 'Market';
      id: string;
      channel: string;
      isActive: boolean;
      type: MarketType;
      createdAt: any;
    }>;
  };
};

export type GetMarketQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetMarketQuery = {
  __typename?: 'Query';
  adminGetMarket: {
    __typename?: 'Market';
    id: string;
    channel: string;
    type: MarketType;
    isActive: boolean;
    fromGateway: {
      __typename?: 'Gateway';
      id: string;
      name: string;
      persianName: string;
      currency: string;
    };
    toGateway: {
      __typename?: 'Gateway';
      id: string;
      name: string;
      persianName: string;
      currency: string;
    };
  };
};

export type CreateUpdateMarketMutationVariables = Exact<{
  input: AdminCreateUpdateMarketInput;
}>;

export type CreateUpdateMarketMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateMarket: { __typename?: 'Market'; id: string; channel: string };
};

export type DeleteMarketsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteMarketsMutation = { __typename?: 'Mutation'; adminDeleteMarkets: boolean };

export type NetworkFragmentFragment = {
  __typename?: 'Network';
  id: string;
  createdAt: any;
  updatedAt: any;
  network: string;
  networkName: string;
  fee: string;
  isActive: boolean;
  gatewayId: string;
  gateway: { __typename?: 'Gateway'; name: string };
};

export type ListNetworkFragmentFragment = {
  __typename?: 'Network';
  id: string;
  createdAt: any;
  updatedAt: any;
  network: string;
  networkName: string;
  fee: string;
  isActive: boolean;
  gateway: { __typename?: 'Gateway'; name: string };
};

export type GetNetworksQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<AdminGetNetworksFilterInput>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetNetworksQuery = {
  __typename?: 'Query';
  adminGetNetworks: {
    __typename?: 'NetworkPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    networks: Array<{
      __typename?: 'Network';
      id: string;
      createdAt: any;
      updatedAt: any;
      network: string;
      networkName: string;
      fee: string;
      isActive: boolean;
      gateway: { __typename?: 'Gateway'; name: string };
    }>;
  };
};

export type GetNetworkQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetNetworkQuery = {
  __typename?: 'Query';
  adminGetNetwork: {
    __typename?: 'Network';
    id: string;
    createdAt: any;
    updatedAt: any;
    network: string;
    networkName: string;
    fee: string;
    isActive: boolean;
    gatewayId: string;
    gateway: { __typename?: 'Gateway'; name: string };
  };
};

export type DeleteNetworksMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteNetworksMutation = { __typename?: 'Mutation'; adminDeleteNetworks: boolean };

export type RateFragmentFragment = {
  __typename?: 'Rate';
  id: string;
  createdAt: any;
  ratePercent?: number | null | undefined;
  rateFrom?: string | null | undefined;
  rateTo?: string | null | undefined;
  isAuto: boolean;
  sendGatewayId: string;
  receiveGatewayId: string;
  sendGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
  receiveGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
};

export type ListRateFragmentFragment = {
  __typename?: 'Rate';
  id: string;
  createdAt: any;
  ratePercent?: number | null | undefined;
  rateFrom?: string | null | undefined;
  rateTo?: string | null | undefined;
  isAuto: boolean;
  sendGateway: { __typename?: 'Gateway'; name: string; currency: string };
  receiveGateway: { __typename?: 'Gateway'; name: string; currency: string };
};

export type GetRatesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<AdminGetRatesFilterInput>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetRatesQuery = {
  __typename?: 'Query';
  adminGetRates: {
    __typename?: 'RatePaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    rates: Array<{
      __typename?: 'Rate';
      id: string;
      createdAt: any;
      ratePercent?: number | null | undefined;
      rateFrom?: string | null | undefined;
      rateTo?: string | null | undefined;
      isAuto: boolean;
      sendGateway: { __typename?: 'Gateway'; name: string; currency: string };
      receiveGateway: { __typename?: 'Gateway'; name: string; currency: string };
    }>;
  };
};

export type GetRateQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetRateQuery = {
  __typename?: 'Query';
  adminGetRate: {
    __typename?: 'Rate';
    id: string;
    createdAt: any;
    ratePercent?: number | null | undefined;
    rateFrom?: string | null | undefined;
    rateTo?: string | null | undefined;
    isAuto: boolean;
    sendGatewayId: string;
    receiveGatewayId: string;
    sendGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
    receiveGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
  };
};

export type CreateUpdateRateMutationVariables = Exact<{
  input: AdminCreateUpdateRateInput;
}>;

export type CreateUpdateRateMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateRate: {
    __typename?: 'Rate';
    id: string;
    createdAt: any;
    ratePercent?: number | null | undefined;
    rateFrom?: string | null | undefined;
    rateTo?: string | null | undefined;
    isAuto: boolean;
    sendGatewayId: string;
    receiveGatewayId: string;
    sendGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
    receiveGateway: { __typename?: 'Gateway'; name: string; persianName: string; currency: string };
  };
};

export type DeleteRatesMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteRatesMutation = { __typename?: 'Mutation'; adminDeleteRates: boolean };

export type SettingFragmentFragment = {
  __typename?: 'Settings';
  usdPrice?: string | null | undefined;
  referralCommission?: string | null | undefined;
  adminMobile?: string | null | undefined;
  normalTradeCommission: number;
  bronzeTradeCommission: number;
  silverTradeCommission: number;
  goldTradeCommission: number;
  vipTradeCommission: number;
  normalDailyWithdraw: number;
  bronzeDailyWithdraw: number;
  silverDailyWithdraw: number;
  goldDailyWithdraw: number;
  vipDailyWithdraw: number;
};

export type GetSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSettingsQuery = {
  __typename?: 'Query';
  adminGetSettings?:
    | {
        __typename?: 'Settings';
        usdPrice?: string | null | undefined;
        referralCommission?: string | null | undefined;
        adminMobile?: string | null | undefined;
        normalTradeCommission: number;
        bronzeTradeCommission: number;
        silverTradeCommission: number;
        goldTradeCommission: number;
        vipTradeCommission: number;
        normalDailyWithdraw: number;
        bronzeDailyWithdraw: number;
        silverDailyWithdraw: number;
        goldDailyWithdraw: number;
        vipDailyWithdraw: number;
      }
    | null
    | undefined;
};

export type CreateUpdateSettingMutationVariables = Exact<{
  input: AdminCreateUpdateSettingInput;
}>;

export type CreateUpdateSettingMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateSetting: {
    __typename?: 'Settings';
    usdPrice?: string | null | undefined;
    referralCommission?: string | null | undefined;
    adminMobile?: string | null | undefined;
    normalTradeCommission: number;
    bronzeTradeCommission: number;
    silverTradeCommission: number;
    goldTradeCommission: number;
    vipTradeCommission: number;
    normalDailyWithdraw: number;
    bronzeDailyWithdraw: number;
    silverDailyWithdraw: number;
    goldDailyWithdraw: number;
    vipDailyWithdraw: number;
  };
};

export type ListTicketFragmentFragment = {
  __typename?: 'Tickets';
  id: string;
  reference: string;
  title: string;
  status: TicketStatus;
  createdAt: any;
  updatedAt: any;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type GetTicketsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetTicketsQuery = {
  __typename?: 'Query';
  adminGetTickets: {
    __typename?: 'TicketPaginationResponse';
    limit?: number | null | undefined;
    count: number;
    offset: number;
    tickets: Array<{
      __typename?: 'Tickets';
      id: string;
      reference: string;
      title: string;
      status: TicketStatus;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    }>;
  };
};

export type GetTicketQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetTicketQuery = {
  __typename?: 'Query';
  adminGetTicket: {
    __typename?: 'Tickets';
    id: string;
    reference: string;
    title: string;
    status: TicketStatus;
    createdAt: any;
    updatedAt: any;
    messages: Array<{
      __typename?: 'TicketMessages';
      id: string;
      fileLink?: string | null | undefined;
      content?: string | null | undefined;
      userId?: string | null | undefined;
      adminId?: string | null | undefined;
      createdAt: any;
      user?: { __typename?: 'User'; firstName: string; lastName: string } | null | undefined;
      admin?: { __typename?: 'Admin'; firstName: string; lastName: string } | null | undefined;
    }>;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type AddMessageToTicketMutationVariables = Exact<{
  input: AddMessageToTicket;
  file?: Maybe<Scalars['Upload']>;
}>;

export type AddMessageToTicketMutation = {
  __typename?: 'Mutation';
  adminAddMessageToTicket: { __typename?: 'TicketMessages'; id: string };
};

export type UpdateTicketStatusMutationVariables = Exact<{
  input: AdminUpdateTicketStatusInput;
}>;

export type UpdateTicketStatusMutation = {
  __typename?: 'Mutation';
  adminUpdateTicketStatus: { __typename?: 'Tickets'; id: string };
};

export type ListTradeFragmentFragment = {
  __typename?: 'Trade';
  id: string;
  reference: string;
  status: TradeStatus;
  side: TradeSide;
  type: TradeType;
  amount?: number | null | undefined;
  price?: number | null | undefined;
  stop?: number | null | undefined;
  createdAt: any;
  updatedAt: any;
  market: { __typename?: 'Market'; channel: string };
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type TradeFragmentFragment = {
  __typename?: 'Trade';
  id: string;
  reference: string;
  status: TradeStatus;
  side: TradeSide;
  type: TradeType;
  amount?: number | null | undefined;
  price?: number | null | undefined;
  stop?: number | null | undefined;
  createdAt: any;
  updatedAt: any;
  commission?: number | null | undefined;
  market: { __typename?: 'Market'; channel: string };
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type GetTradesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetTradesQuery = {
  __typename?: 'Query';
  adminGetTrades: {
    __typename?: 'TradePaginationResponse';
    limit?: number | null | undefined;
    count: number;
    offset: number;
    trades: Array<{
      __typename?: 'Trade';
      id: string;
      reference: string;
      status: TradeStatus;
      side: TradeSide;
      type: TradeType;
      amount?: number | null | undefined;
      price?: number | null | undefined;
      stop?: number | null | undefined;
      createdAt: any;
      updatedAt: any;
      market: { __typename?: 'Market'; channel: string };
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    }>;
  };
};

export type GetTradeQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetTradeQuery = {
  __typename?: 'Query';
  adminGetTrade: {
    __typename?: 'Trade';
    id: string;
    reference: string;
    status: TradeStatus;
    side: TradeSide;
    type: TradeType;
    amount?: number | null | undefined;
    price?: number | null | undefined;
    stop?: number | null | undefined;
    createdAt: any;
    updatedAt: any;
    commission?: number | null | undefined;
    market: { __typename?: 'Market'; channel: string };
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type TransactionFragmentFragment = {
  __typename?: 'Transaction';
  id: string;
  createdAt: any;
  amount: string;
  currency?: string | null | undefined;
  cardNumber?: string | null | undefined;
  txId?: string | null | undefined;
  description?: string | null | undefined;
  userId: string;
  type: TransactionType;
  merchantName: string;
  incremental: boolean;
  status: TransactionStatus;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type ListTransactionFragmentFragment = {
  __typename?: 'Transaction';
  id: string;
  createdAt: any;
  amount: string;
  currency?: string | null | undefined;
  type: TransactionType;
  incremental: boolean;
  status: TransactionStatus;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type GetTransactionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetTransactionsQuery = {
  __typename?: 'Query';
  adminGetTransactions: {
    __typename?: 'TransactionPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    transactions: Array<{
      __typename?: 'Transaction';
      id: string;
      createdAt: any;
      amount: string;
      currency?: string | null | undefined;
      type: TransactionType;
      incremental: boolean;
      status: TransactionStatus;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    }>;
  };
};

export type GetTransactionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetTransactionQuery = {
  __typename?: 'Query';
  adminGetTransaction: {
    __typename?: 'Transaction';
    id: string;
    createdAt: any;
    amount: string;
    currency?: string | null | undefined;
    cardNumber?: string | null | undefined;
    txId?: string | null | undefined;
    description?: string | null | undefined;
    userId: string;
    type: TransactionType;
    merchantName: string;
    incremental: boolean;
    status: TransactionStatus;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  createdAt: any;
  firstName: string;
  lastName: string;
  email: string;
  emailVerifiedAt?: any | null | undefined;
  mobileNumber?: string | null | undefined;
  mobileVerifiedAt?: any | null | undefined;
  nationalCode?: string | null | undefined;
  birthCertificateCode?: string | null | undefined;
  fatherName?: string | null | undefined;
  birthDate?: string | null | undefined;
  country?: string | null | undefined;
  address?: string | null | undefined;
  phone?: string | null | undefined;
  ipAddress: string;
  status: UserStatus;
  wallets: {
    __typename?: 'UserWalletPaginationResponse';
    userwallets: Array<{
      __typename?: 'UserWallet';
      id: string;
      createdAt: any;
      amount: string;
      gateway: { __typename?: 'Gateway'; id: string; name: string; currency: string };
    }>;
  };
  documents: {
    __typename?: 'UserDocumentPaginationResponse';
    userdocuments: Array<{
      __typename?: 'UserDocument';
      id: string;
      fileLink?: string | null | undefined;
      type: DocumentType;
      status: DocumentStatus;
      createdAt: any;
      updatedAt: any;
    }>;
  };
  bankAccounts: {
    __typename?: 'BankAccountPaginationResponse';
    bankaccounts: Array<{
      __typename?: 'BankAccount';
      id: string;
      createdAt: any;
      bank: string;
      cardNumber: string;
      shebaNumber: string;
      isVerify: boolean;
    }>;
  };
  walletHistories: {
    __typename?: 'UserWalletHistoryPaginationResponse';
    userwallethistorys: Array<{
      __typename?: 'UserWalletHistory';
      id: string;
      createdAt: any;
      amount: string;
      credited: boolean;
      adminId?: string | null | undefined;
      userDeposit?: { __typename?: 'UserDeposit'; reference: string } | null | undefined;
      userWithdrawal?: { __typename?: 'UserWithdrawals'; reference: string } | null | undefined;
      trade?: { __typename?: 'Trade'; reference: string } | null | undefined;
      convert?: { __typename?: 'Convert'; reference: string } | null | undefined;
      commissionerUser?:
        | { __typename?: 'User'; firstName: string; lastName: string }
        | null
        | undefined;
    }>;
  };
};

export type ListUserFragmentFragment = {
  __typename?: 'User';
  id: string;
  createdAt: any;
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatus;
};

export type GetUsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<AdminGetUsersFilterInput>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  adminGetUsers: {
    __typename?: 'UserPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    users: Array<{
      __typename?: 'User';
      id: string;
      createdAt: any;
      firstName: string;
      lastName: string;
      email: string;
      status: UserStatus;
    }>;
  };
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  adminGetUser: {
    __typename?: 'User';
    id: string;
    createdAt: any;
    firstName: string;
    lastName: string;
    email: string;
    emailVerifiedAt?: any | null | undefined;
    mobileNumber?: string | null | undefined;
    mobileVerifiedAt?: any | null | undefined;
    nationalCode?: string | null | undefined;
    birthCertificateCode?: string | null | undefined;
    fatherName?: string | null | undefined;
    birthDate?: string | null | undefined;
    country?: string | null | undefined;
    address?: string | null | undefined;
    phone?: string | null | undefined;
    ipAddress: string;
    status: UserStatus;
    wallets: {
      __typename?: 'UserWalletPaginationResponse';
      userwallets: Array<{
        __typename?: 'UserWallet';
        id: string;
        createdAt: any;
        amount: string;
        gateway: { __typename?: 'Gateway'; id: string; name: string; currency: string };
      }>;
    };
    documents: {
      __typename?: 'UserDocumentPaginationResponse';
      userdocuments: Array<{
        __typename?: 'UserDocument';
        id: string;
        fileLink?: string | null | undefined;
        type: DocumentType;
        status: DocumentStatus;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    bankAccounts: {
      __typename?: 'BankAccountPaginationResponse';
      bankaccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        createdAt: any;
        bank: string;
        cardNumber: string;
        shebaNumber: string;
        isVerify: boolean;
      }>;
    };
    walletHistories: {
      __typename?: 'UserWalletHistoryPaginationResponse';
      userwallethistorys: Array<{
        __typename?: 'UserWalletHistory';
        id: string;
        createdAt: any;
        amount: string;
        credited: boolean;
        adminId?: string | null | undefined;
        userDeposit?: { __typename?: 'UserDeposit'; reference: string } | null | undefined;
        userWithdrawal?: { __typename?: 'UserWithdrawals'; reference: string } | null | undefined;
        trade?: { __typename?: 'Trade'; reference: string } | null | undefined;
        convert?: { __typename?: 'Convert'; reference: string } | null | undefined;
        commissionerUser?:
          | { __typename?: 'User'; firstName: string; lastName: string }
          | null
          | undefined;
      }>;
    };
  };
};

export type CreateUpdateUserMutationVariables = Exact<{
  input: AdminCreateUpdateUserInput;
}>;

export type CreateUpdateUserMutation = {
  __typename?: 'Mutation';
  adminCreateUpdateUser: {
    __typename?: 'User';
    id: string;
    createdAt: any;
    firstName: string;
    lastName: string;
    email: string;
    emailVerifiedAt?: any | null | undefined;
    mobileNumber?: string | null | undefined;
    mobileVerifiedAt?: any | null | undefined;
    nationalCode?: string | null | undefined;
    birthCertificateCode?: string | null | undefined;
    fatherName?: string | null | undefined;
    birthDate?: string | null | undefined;
    country?: string | null | undefined;
    address?: string | null | undefined;
    phone?: string | null | undefined;
    ipAddress: string;
    status: UserStatus;
    wallets: {
      __typename?: 'UserWalletPaginationResponse';
      userwallets: Array<{
        __typename?: 'UserWallet';
        id: string;
        createdAt: any;
        amount: string;
        gateway: { __typename?: 'Gateway'; id: string; name: string; currency: string };
      }>;
    };
    documents: {
      __typename?: 'UserDocumentPaginationResponse';
      userdocuments: Array<{
        __typename?: 'UserDocument';
        id: string;
        fileLink?: string | null | undefined;
        type: DocumentType;
        status: DocumentStatus;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    bankAccounts: {
      __typename?: 'BankAccountPaginationResponse';
      bankaccounts: Array<{
        __typename?: 'BankAccount';
        id: string;
        createdAt: any;
        bank: string;
        cardNumber: string;
        shebaNumber: string;
        isVerify: boolean;
      }>;
    };
    walletHistories: {
      __typename?: 'UserWalletHistoryPaginationResponse';
      userwallethistorys: Array<{
        __typename?: 'UserWalletHistory';
        id: string;
        createdAt: any;
        amount: string;
        credited: boolean;
        adminId?: string | null | undefined;
        userDeposit?: { __typename?: 'UserDeposit'; reference: string } | null | undefined;
        userWithdrawal?: { __typename?: 'UserWithdrawals'; reference: string } | null | undefined;
        trade?: { __typename?: 'Trade'; reference: string } | null | undefined;
        convert?: { __typename?: 'Convert'; reference: string } | null | undefined;
        commissionerUser?:
          | { __typename?: 'User'; firstName: string; lastName: string }
          | null
          | undefined;
      }>;
    };
  };
};

export type CreateUpdateUserWalletMutationVariables = Exact<{
  input: AdminCreditDebitUserWalletInput;
}>;

export type CreateUpdateUserWalletMutation = {
  __typename?: 'Mutation';
  adminCreditDebitUserWallet: { __typename?: 'UserWallet'; id: string };
};

export type DeleteUsersMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteUsersMutation = { __typename?: 'Mutation'; adminDeleteUsers: boolean };

export type DeleteUserWalletsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteUserWalletsMutation = {
  __typename?: 'Mutation';
  adminDeleteUserWallets: boolean;
};

export type UserBankAccountFragmentFragment = {
  __typename?: 'BankAccount';
  id: string;
  createdAt: any;
  bank: string;
  cardNumber: string;
  shebaNumber: string;
  isVerify: boolean;
};

export type ListUserBankAccountFragmentFragment = {
  __typename?: 'BankAccount';
  id: string;
  createdAt: any;
  bank: string;
  cardNumber: string;
  shebaNumber: string;
  isVerify: boolean;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type GetUserBankAccountsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<AdminGetBankAccountsFilterInput>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetUserBankAccountsQuery = {
  __typename?: 'Query';
  adminGetBankAccounts: {
    __typename?: 'BankAccountPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    bankaccounts: Array<{
      __typename?: 'BankAccount';
      id: string;
      createdAt: any;
      bank: string;
      cardNumber: string;
      shebaNumber: string;
      isVerify: boolean;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    }>;
  };
};

export type GetUserBankAccountQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserBankAccountQuery = {
  __typename?: 'Query';
  adminGetBankAccount: {
    __typename?: 'BankAccount';
    id: string;
    createdAt: any;
    bank: string;
    cardNumber: string;
    shebaNumber: string;
    isVerify: boolean;
  };
};

export type UpdateUserBankAccountMutationVariables = Exact<{
  input: AdminUpdateBankAccountInput;
}>;

export type UpdateUserBankAccountMutation = {
  __typename?: 'Mutation';
  adminUpdateBankAccount: {
    __typename?: 'BankAccount';
    id: string;
    createdAt: any;
    bank: string;
    cardNumber: string;
    shebaNumber: string;
    isVerify: boolean;
  };
};

export type DeleteUserBankAccountsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteUserBankAccountsMutation = {
  __typename?: 'Mutation';
  adminDeleteBankAccounts: boolean;
};

export type ListDocumentFragmentFragment = {
  __typename?: 'UserDocument';
  id: string;
  createdAt: any;
  type: DocumentType;
  status: DocumentStatus;
  fileLink?: string | null | undefined;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type GetUserDocumentsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetUserDocumentsQuery = {
  __typename?: 'Query';
  adminGetUserDocuments: {
    __typename?: 'UserDocumentPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    userdocuments: Array<{
      __typename?: 'UserDocument';
      id: string;
      createdAt: any;
      type: DocumentType;
      status: DocumentStatus;
      fileLink?: string | null | undefined;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    }>;
  };
};

export type GetUserDocumentQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserDocumentQuery = {
  __typename?: 'Query';
  adminGetUserDocument: {
    __typename?: 'UserDocument';
    id: string;
    createdAt: any;
    type: DocumentType;
    status: DocumentStatus;
    fileLink?: string | null | undefined;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type UpdateUserDocumentStatusMutationVariables = Exact<{
  input: AdminUpdateUserDocumentStatusInput;
}>;

export type UpdateUserDocumentStatusMutation = {
  __typename?: 'Mutation';
  adminUpdateUserDocumentStatus: { __typename?: 'UserDocument'; id: string };
};

export type DeleteUserDocumentsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteUserDocumentsMutation = {
  __typename?: 'Mutation';
  adminDeleteUserDocuments: boolean;
};

export type GetWalletsWithBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type GetWalletsWithBalanceQuery = {
  __typename?: 'Query';
  adminGetAllGatewaysWithWallet: Array<{
    __typename?: 'GatewaysWithWallet';
    id: string;
    name: string;
    currency: string;
    price?: string | null | undefined;
    totalAmount: number;
  }>;
};

export type GetUserWalletsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<AdminGetUserWalletsFilterInput>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetUserWalletsQuery = {
  __typename?: 'Query';
  adminGetUserWallets: {
    __typename?: 'UserWalletPaginationResponse';
    count: number;
    limit?: number | null | undefined;
    offset: number;
    userwallets: Array<{
      __typename?: 'UserWallet';
      id: string;
      updatedAt: any;
      amount: string;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
      gateway: {
        __typename?: 'Gateway';
        id: string;
        name: string;
        currency: string;
        price?: string | null | undefined;
      };
    }>;
  };
};

export type ListWithdrawalFragmentFragment = {
  __typename?: 'UserWithdrawals';
  id: string;
  reference: string;
  amount: string;
  currency: string;
  status?: WithdrawalStatus | null | undefined;
  createdAt: any;
  updatedAt: any;
  gateway?: { __typename?: 'Gateway'; name: string } | null | undefined;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type WithdrawalFragmentFragment = {
  __typename?: 'UserWithdrawals';
  id: string;
  reference: string;
  amount: string;
  currency: string;
  status?: WithdrawalStatus | null | undefined;
  destinationAddress?: string | null | undefined;
  memo?: string | null | undefined;
  txId?: string | null | undefined;
  voucherDetail?: string | null | undefined;
  ipAddress: string;
  createdAt: any;
  updatedAt: any;
  gateway?:
    | { __typename?: 'Gateway'; name: string; persianName: string; merchant: MerchantType }
    | null
    | undefined;
  network?: { __typename?: 'Network'; networkName: string } | null | undefined;
  bankAccount?:
    | { __typename?: 'BankAccount'; bank: string; cardNumber: string; shebaNumber: string }
    | null
    | undefined;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type GetWithdrawalsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<Scalars['String']>;
}>;

export type GetWithdrawalsQuery = {
  __typename?: 'Query';
  adminGetUserWithdrawals: {
    __typename?: 'UserWithdrawalPaginationResponse';
    limit?: number | null | undefined;
    count: number;
    offset: number;
    userwithdrawals: Array<{
      __typename?: 'UserWithdrawals';
      id: string;
      reference: string;
      amount: string;
      currency: string;
      status?: WithdrawalStatus | null | undefined;
      createdAt: any;
      updatedAt: any;
      gateway?: { __typename?: 'Gateway'; name: string } | null | undefined;
      user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    }>;
  };
};

export type GetWithdrawalQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetWithdrawalQuery = {
  __typename?: 'Query';
  adminGetUserWithdrawal: {
    __typename?: 'UserWithdrawals';
    id: string;
    reference: string;
    amount: string;
    currency: string;
    status?: WithdrawalStatus | null | undefined;
    destinationAddress?: string | null | undefined;
    memo?: string | null | undefined;
    txId?: string | null | undefined;
    voucherDetail?: string | null | undefined;
    ipAddress: string;
    createdAt: any;
    updatedAt: any;
    gateway?:
      | { __typename?: 'Gateway'; name: string; persianName: string; merchant: MerchantType }
      | null
      | undefined;
    network?: { __typename?: 'Network'; networkName: string } | null | undefined;
    bankAccount?:
      | { __typename?: 'BankAccount'; bank: string; cardNumber: string; shebaNumber: string }
      | null
      | undefined;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  };
};

export type UpdateWithdrawStatusMutationVariables = Exact<{
  input: UpdateWithdrawInput;
}>;

export type UpdateWithdrawStatusMutation = {
  __typename?: 'Mutation';
  adminUpdateWithdrawal: boolean;
};

export const SupportAdminFragmentFragmentDoc = gql`
  fragment SupportAdminFragment on Admin {
    id
    createdAt
    firstName
    lastName
    email
    role
  }
`;
export const AnnouncementFragmentFragmentDoc = gql`
  fragment AnnouncementFragment on Announcement {
    id
    createdAt
    updatedAt
    title
    content
    lang
  }
`;
export const ListAnnouncementFragmentFragmentDoc = gql`
  fragment ListAnnouncementFragment on Announcement {
    id
    createdAt
    updatedAt
    title
    lang
  }
`;
export const AppSliderFragmentFragmentDoc = gql`
  fragment AppSliderFragment on AppSlider {
    id
    createdAt
    updatedAt
    imageLink
    name
    type
    lang
  }
`;
export const ListAppSliderFragmentFragmentDoc = gql`
  fragment ListAppSliderFragment on AppSlider {
    id
    createdAt
    updatedAt
    imageLink
    name
    type
    lang
  }
`;
export const AdminFragmentFragmentDoc = gql`
  fragment AdminFragment on Admin {
    id
    firstName
    lastName
    email
  }
`;
export const GatewayBankFragmentFragmentDoc = gql`
  fragment GatewayBankFragment on BankGateway {
    id
    createdAt
    updatedAt
    name
    persianName
    isActive
    type
    merchantId
    destinationCartNumber
    destinationIban
    destinationName
  }
`;
export const ListDepositFragmentFragmentDoc = gql`
  fragment ListDepositFragment on UserDeposit {
    id
    reference
    amount
    gateway {
      name
      persianName
    }
    user {
      id
      firstName
      lastName
    }
    currency
    status
    createdAt
    updatedAt
  }
`;
export const DepositFragmentFragmentDoc = gql`
  fragment DepositFragment on UserDeposit {
    id
    reference
    amount
    currency
    status
    txId
    sourceAddress
    gateway {
      name
      persianName
      merchant
    }
    network {
      networkName
    }
    bankGateway {
      type
    }
    user {
      id
      firstName
      lastName
    }
    ipAddress
    description
    createdAt
    updatedAt
  }
`;
export const ListExchangeFragmentFragmentDoc = gql`
  fragment ListExchangeFragment on Convert {
    id
    reference
    sendAmount
    receiveAmount
    buyCompleted
    sellCompleted
    user {
      id
      firstName
      lastName
    }
    sendGateway {
      name
      persianName
      currency
    }
    receiveGateway {
      name
      persianName
      currency
    }
    createdAt
    updatedAt
  }
`;
export const ExchangeFragmentFragmentDoc = gql`
  fragment ExchangeFragment on Convert {
    id
    reference
    sendAmount
    receiveAmount
    sendRate
    receiveRate
    buyCompleted
    sellCompleted
    commission
    user {
      id
      firstName
      lastName
    }
    sendGateway {
      name
      persianName
      currency
    }
    receiveGateway {
      name
      persianName
      currency
    }
    ipAddress
    createdAt
    updatedAt
  }
`;
export const GatewayFragmentFragmentDoc = gql`
  fragment GatewayFragment on Gateway {
    id
    createdAt
    updatedAt
    name
    persianName
    currency
    minAmount
    maxAmount
    reserve
    sortOrder
    bestCode
    isCrypto
    allowSend
    isAuto
    icon
    isActive
    merchant
    field_1
  }
`;
export const ListGatewayFragmentFragmentDoc = gql`
  fragment ListGatewayFragment on Gateway {
    id
    createdAt
    name
    persianName
    currency
    isActive
  }
`;
export const NetworkFragmentFragmentDoc = gql`
  fragment NetworkFragment on Network {
    id
    createdAt
    updatedAt
    network
    networkName
    fee
    isActive
    gateway {
      name
    }
    gatewayId
  }
`;
export const ListNetworkFragmentFragmentDoc = gql`
  fragment ListNetworkFragment on Network {
    id
    createdAt
    updatedAt
    network
    networkName
    fee
    isActive
    gateway {
      name
    }
  }
`;
export const RateFragmentFragmentDoc = gql`
  fragment RateFragment on Rate {
    id
    createdAt
    ratePercent
    rateFrom
    rateTo
    isAuto
    sendGatewayId
    receiveGatewayId
    sendGateway {
      name
      persianName
      currency
    }
    receiveGateway {
      name
      persianName
      currency
    }
  }
`;
export const ListRateFragmentFragmentDoc = gql`
  fragment ListRateFragment on Rate {
    id
    createdAt
    ratePercent
    rateFrom
    rateTo
    isAuto
    sendGateway {
      name
      currency
    }
    receiveGateway {
      name
      currency
    }
  }
`;
export const SettingFragmentFragmentDoc = gql`
  fragment SettingFragment on Settings {
    usdPrice
    referralCommission
    adminMobile
    normalTradeCommission
    bronzeTradeCommission
    silverTradeCommission
    goldTradeCommission
    vipTradeCommission
    normalDailyWithdraw
    bronzeDailyWithdraw
    silverDailyWithdraw
    goldDailyWithdraw
    vipDailyWithdraw
  }
`;
export const ListTicketFragmentFragmentDoc = gql`
  fragment ListTicketFragment on Tickets {
    id
    reference
    title
    status
    user {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
  }
`;
export const ListTradeFragmentFragmentDoc = gql`
  fragment ListTradeFragment on Trade {
    id
    reference
    status
    side
    type
    amount
    price
    stop
    createdAt
    updatedAt
    market {
      channel
    }
    user {
      id
      firstName
      lastName
    }
  }
`;
export const TradeFragmentFragmentDoc = gql`
  fragment TradeFragment on Trade {
    id
    reference
    status
    side
    type
    amount
    price
    stop
    createdAt
    updatedAt
    market {
      channel
    }
    user {
      id
      firstName
      lastName
    }
    commission
  }
`;
export const TransactionFragmentFragmentDoc = gql`
  fragment TransactionFragment on Transaction {
    id
    createdAt
    amount
    currency
    cardNumber
    txId
    description
    user {
      id
      firstName
      lastName
    }
    userId
    type
    merchantName
    incremental
    status
  }
`;
export const ListTransactionFragmentFragmentDoc = gql`
  fragment ListTransactionFragment on Transaction {
    id
    createdAt
    amount
    currency
    user {
      id
      firstName
      lastName
    }
    type
    incremental
    status
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    createdAt
    firstName
    lastName
    email
    emailVerifiedAt
    mobileNumber
    mobileVerifiedAt
    nationalCode
    birthCertificateCode
    fatherName
    birthDate
    country
    address
    phone
    ipAddress
    status
    wallets {
      userwallets {
        id
        createdAt
        amount
        gateway {
          id
          name
          currency
        }
      }
    }
    documents {
      userdocuments {
        id
        fileLink
        type
        status
        createdAt
        updatedAt
      }
    }
    bankAccounts {
      bankaccounts {
        id
        createdAt
        bank
        cardNumber
        shebaNumber
        isVerify
      }
    }
    walletHistories {
      userwallethistorys {
        id
        createdAt
        amount
        credited
        userDeposit {
          reference
        }
        userWithdrawal {
          reference
        }
        trade {
          reference
        }
        convert {
          reference
        }
        commissionerUser {
          firstName
          lastName
        }
        adminId
      }
    }
  }
`;
export const ListUserFragmentFragmentDoc = gql`
  fragment ListUserFragment on User {
    id
    createdAt
    firstName
    lastName
    email
    status
  }
`;
export const UserBankAccountFragmentFragmentDoc = gql`
  fragment UserBankAccountFragment on BankAccount {
    id
    createdAt
    bank
    cardNumber
    shebaNumber
    isVerify
  }
`;
export const ListUserBankAccountFragmentFragmentDoc = gql`
  fragment ListUserBankAccountFragment on BankAccount {
    id
    createdAt
    bank
    cardNumber
    shebaNumber
    isVerify
    user {
      id
      firstName
      lastName
    }
  }
`;
export const ListDocumentFragmentFragmentDoc = gql`
  fragment ListDocumentFragment on UserDocument {
    id
    createdAt
    type
    status
    fileLink
    user {
      id
      firstName
      lastName
    }
  }
`;
export const ListWithdrawalFragmentFragmentDoc = gql`
  fragment ListWithdrawalFragment on UserWithdrawals {
    id
    reference
    amount
    gateway {
      name
    }
    user {
      id
      firstName
      lastName
    }
    currency
    status
    createdAt
    updatedAt
  }
`;
export const WithdrawalFragmentFragmentDoc = gql`
  fragment WithdrawalFragment on UserWithdrawals {
    id
    reference
    amount
    currency
    status
    destinationAddress
    gateway {
      name
      persianName
      merchant
    }
    network {
      networkName
    }
    memo
    bankAccount {
      bank
      cardNumber
      shebaNumber
    }
    user {
      id
      firstName
      lastName
    }
    txId
    voucherDetail
    ipAddress
    createdAt
    updatedAt
  }
`;
export const GetSupportAdminsDocument = gql`
  query GetSupportAdmins($limit: Int!, $offset: Int!, $order: SortOrder, $orderBy: String) {
    adminGetSupportAdmins(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      admins {
        ...SupportAdminFragment
      }
      count
      limit
      offset
    }
  }
  ${SupportAdminFragmentFragmentDoc}
`;

/**
 * __useGetSupportAdminsQuery__
 *
 * To run a query within a React component, call `useGetSupportAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportAdminsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSupportAdminsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSupportAdminsQuery, GetSupportAdminsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupportAdminsQuery, GetSupportAdminsQueryVariables>(
    GetSupportAdminsDocument,
    options
  );
}
export function useGetSupportAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSupportAdminsQuery, GetSupportAdminsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupportAdminsQuery, GetSupportAdminsQueryVariables>(
    GetSupportAdminsDocument,
    options
  );
}
export type GetSupportAdminsQueryHookResult = ReturnType<typeof useGetSupportAdminsQuery>;
export type GetSupportAdminsLazyQueryHookResult = ReturnType<typeof useGetSupportAdminsLazyQuery>;
export type GetSupportAdminsQueryResult = Apollo.QueryResult<
  GetSupportAdminsQuery,
  GetSupportAdminsQueryVariables
>;
export const GetSupportAdminDocument = gql`
  query GetSupportAdmin($id: String!) {
    adminGetSupportAdmin(id: $id) {
      ...SupportAdminFragment
    }
  }
  ${SupportAdminFragmentFragmentDoc}
`;

/**
 * __useGetSupportAdminQuery__
 *
 * To run a query within a React component, call `useGetSupportAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupportAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetSupportAdminQuery, GetSupportAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupportAdminQuery, GetSupportAdminQueryVariables>(
    GetSupportAdminDocument,
    options
  );
}
export function useGetSupportAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSupportAdminQuery, GetSupportAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupportAdminQuery, GetSupportAdminQueryVariables>(
    GetSupportAdminDocument,
    options
  );
}
export type GetSupportAdminQueryHookResult = ReturnType<typeof useGetSupportAdminQuery>;
export type GetSupportAdminLazyQueryHookResult = ReturnType<typeof useGetSupportAdminLazyQuery>;
export type GetSupportAdminQueryResult = Apollo.QueryResult<
  GetSupportAdminQuery,
  GetSupportAdminQueryVariables
>;
export const CreateUpdateSupportAdminDocument = gql`
  mutation CreateUpdateSupportAdmin($input: AdminCreateUpdateSupportAdminInput!) {
    adminCreateUpdateSupportAdmin(input: $input) {
      ...SupportAdminFragment
    }
  }
  ${SupportAdminFragmentFragmentDoc}
`;
export type CreateUpdateSupportAdminMutationFn = Apollo.MutationFunction<
  CreateUpdateSupportAdminMutation,
  CreateUpdateSupportAdminMutationVariables
>;

/**
 * __useCreateUpdateSupportAdminMutation__
 *
 * To run a mutation, you first call `useCreateUpdateSupportAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateSupportAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateSupportAdminMutation, { data, loading, error }] = useCreateUpdateSupportAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateSupportAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateSupportAdminMutation,
    CreateUpdateSupportAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUpdateSupportAdminMutation,
    CreateUpdateSupportAdminMutationVariables
  >(CreateUpdateSupportAdminDocument, options);
}
export type CreateUpdateSupportAdminMutationHookResult = ReturnType<
  typeof useCreateUpdateSupportAdminMutation
>;
export type CreateUpdateSupportAdminMutationResult =
  Apollo.MutationResult<CreateUpdateSupportAdminMutation>;
export type CreateUpdateSupportAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateSupportAdminMutation,
  CreateUpdateSupportAdminMutationVariables
>;
export const DeleteSupportAdminsDocument = gql`
  mutation DeleteSupportAdmins($ids: [String!]!) {
    adminDeleteSupportAdmin(ids: $ids)
  }
`;
export type DeleteSupportAdminsMutationFn = Apollo.MutationFunction<
  DeleteSupportAdminsMutation,
  DeleteSupportAdminsMutationVariables
>;

/**
 * __useDeleteSupportAdminsMutation__
 *
 * To run a mutation, you first call `useDeleteSupportAdminsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupportAdminsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupportAdminsMutation, { data, loading, error }] = useDeleteSupportAdminsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteSupportAdminsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSupportAdminsMutation,
    DeleteSupportAdminsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSupportAdminsMutation, DeleteSupportAdminsMutationVariables>(
    DeleteSupportAdminsDocument,
    options
  );
}
export type DeleteSupportAdminsMutationHookResult = ReturnType<
  typeof useDeleteSupportAdminsMutation
>;
export type DeleteSupportAdminsMutationResult = Apollo.MutationResult<DeleteSupportAdminsMutation>;
export type DeleteSupportAdminsMutationOptions = Apollo.BaseMutationOptions<
  DeleteSupportAdminsMutation,
  DeleteSupportAdminsMutationVariables
>;
export const GetAnnouncementsDocument = gql`
  query GetAnnouncements($limit: Int!, $offset: Int!, $order: SortOrder, $orderBy: String) {
    adminGetAnnouncements(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      announcements {
        ...ListAnnouncementFragment
      }
      count
      limit
      offset
    }
  }
  ${ListAnnouncementFragmentFragmentDoc}
`;

/**
 * __useGetAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAnnouncementsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(
    GetAnnouncementsDocument,
    options
  );
}
export function useGetAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(
    GetAnnouncementsDocument,
    options
  );
}
export type GetAnnouncementsQueryHookResult = ReturnType<typeof useGetAnnouncementsQuery>;
export type GetAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsLazyQuery>;
export type GetAnnouncementsQueryResult = Apollo.QueryResult<
  GetAnnouncementsQuery,
  GetAnnouncementsQueryVariables
>;
export const GetAnnouncementDocument = gql`
  query GetAnnouncement($id: String!) {
    adminGetAnnouncement(id: $id) {
      ...AnnouncementFragment
    }
  }
  ${AnnouncementFragmentFragmentDoc}
`;

/**
 * __useGetAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnnouncementQuery, GetAnnouncementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(
    GetAnnouncementDocument,
    options
  );
}
export function useGetAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementQuery, GetAnnouncementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(
    GetAnnouncementDocument,
    options
  );
}
export type GetAnnouncementQueryHookResult = ReturnType<typeof useGetAnnouncementQuery>;
export type GetAnnouncementLazyQueryHookResult = ReturnType<typeof useGetAnnouncementLazyQuery>;
export type GetAnnouncementQueryResult = Apollo.QueryResult<
  GetAnnouncementQuery,
  GetAnnouncementQueryVariables
>;
export const CreateUpdateAnnouncementDocument = gql`
  mutation CreateUpdateAnnouncement($input: AdminCreateUpdateAnnouncementInput!) {
    adminCreateUpdateAnnouncement(input: $input) {
      ...AnnouncementFragment
    }
  }
  ${AnnouncementFragmentFragmentDoc}
`;
export type CreateUpdateAnnouncementMutationFn = Apollo.MutationFunction<
  CreateUpdateAnnouncementMutation,
  CreateUpdateAnnouncementMutationVariables
>;

/**
 * __useCreateUpdateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateUpdateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateAnnouncementMutation, { data, loading, error }] = useCreateUpdateAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateAnnouncementMutation,
    CreateUpdateAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUpdateAnnouncementMutation,
    CreateUpdateAnnouncementMutationVariables
  >(CreateUpdateAnnouncementDocument, options);
}
export type CreateUpdateAnnouncementMutationHookResult = ReturnType<
  typeof useCreateUpdateAnnouncementMutation
>;
export type CreateUpdateAnnouncementMutationResult =
  Apollo.MutationResult<CreateUpdateAnnouncementMutation>;
export type CreateUpdateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateAnnouncementMutation,
  CreateUpdateAnnouncementMutationVariables
>;
export const DeleteAnnouncementsDocument = gql`
  mutation DeleteAnnouncements($ids: [String!]!) {
    adminDeleteAnnouncements(ids: $ids)
  }
`;
export type DeleteAnnouncementsMutationFn = Apollo.MutationFunction<
  DeleteAnnouncementsMutation,
  DeleteAnnouncementsMutationVariables
>;

/**
 * __useDeleteAnnouncementsMutation__
 *
 * To run a mutation, you first call `useDeleteAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnouncementsMutation, { data, loading, error }] = useDeleteAnnouncementsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteAnnouncementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnnouncementsMutation,
    DeleteAnnouncementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAnnouncementsMutation, DeleteAnnouncementsMutationVariables>(
    DeleteAnnouncementsDocument,
    options
  );
}
export type DeleteAnnouncementsMutationHookResult = ReturnType<
  typeof useDeleteAnnouncementsMutation
>;
export type DeleteAnnouncementsMutationResult = Apollo.MutationResult<DeleteAnnouncementsMutation>;
export type DeleteAnnouncementsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnnouncementsMutation,
  DeleteAnnouncementsMutationVariables
>;
export const GetAppSlidersDocument = gql`
  query GetAppSliders($limit: Int!, $offset: Int!, $order: SortOrder, $orderBy: String) {
    adminGetAppSliders(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      appsliders {
        ...ListAppSliderFragment
      }
      count
      limit
      offset
    }
  }
  ${ListAppSliderFragmentFragmentDoc}
`;

/**
 * __useGetAppSlidersQuery__
 *
 * To run a query within a React component, call `useGetAppSlidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSlidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSlidersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAppSlidersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppSlidersQuery, GetAppSlidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppSlidersQuery, GetAppSlidersQueryVariables>(
    GetAppSlidersDocument,
    options
  );
}
export function useGetAppSlidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppSlidersQuery, GetAppSlidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppSlidersQuery, GetAppSlidersQueryVariables>(
    GetAppSlidersDocument,
    options
  );
}
export type GetAppSlidersQueryHookResult = ReturnType<typeof useGetAppSlidersQuery>;
export type GetAppSlidersLazyQueryHookResult = ReturnType<typeof useGetAppSlidersLazyQuery>;
export type GetAppSlidersQueryResult = Apollo.QueryResult<
  GetAppSlidersQuery,
  GetAppSlidersQueryVariables
>;
export const GetAppSliderDocument = gql`
  query GetAppSlider($id: String!) {
    adminGetAppSlider(id: $id) {
      ...AppSliderFragment
    }
  }
  ${AppSliderFragmentFragmentDoc}
`;

/**
 * __useGetAppSliderQuery__
 *
 * To run a query within a React component, call `useGetAppSliderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSliderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSliderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppSliderQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppSliderQuery, GetAppSliderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppSliderQuery, GetAppSliderQueryVariables>(
    GetAppSliderDocument,
    options
  );
}
export function useGetAppSliderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppSliderQuery, GetAppSliderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppSliderQuery, GetAppSliderQueryVariables>(
    GetAppSliderDocument,
    options
  );
}
export type GetAppSliderQueryHookResult = ReturnType<typeof useGetAppSliderQuery>;
export type GetAppSliderLazyQueryHookResult = ReturnType<typeof useGetAppSliderLazyQuery>;
export type GetAppSliderQueryResult = Apollo.QueryResult<
  GetAppSliderQuery,
  GetAppSliderQueryVariables
>;
export const CreateUpdateAppSliderDocument = gql`
  mutation CreateUpdateAppSlider($input: AdminCreateUpdateAppSliderInput!, $file: Upload) {
    adminCreateUpdateAppSlider(input: $input, file: $file) {
      ...AppSliderFragment
    }
  }
  ${AppSliderFragmentFragmentDoc}
`;
export type CreateUpdateAppSliderMutationFn = Apollo.MutationFunction<
  CreateUpdateAppSliderMutation,
  CreateUpdateAppSliderMutationVariables
>;

/**
 * __useCreateUpdateAppSliderMutation__
 *
 * To run a mutation, you first call `useCreateUpdateAppSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateAppSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateAppSliderMutation, { data, loading, error }] = useCreateUpdateAppSliderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateUpdateAppSliderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateAppSliderMutation,
    CreateUpdateAppSliderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUpdateAppSliderMutation, CreateUpdateAppSliderMutationVariables>(
    CreateUpdateAppSliderDocument,
    options
  );
}
export type CreateUpdateAppSliderMutationHookResult = ReturnType<
  typeof useCreateUpdateAppSliderMutation
>;
export type CreateUpdateAppSliderMutationResult =
  Apollo.MutationResult<CreateUpdateAppSliderMutation>;
export type CreateUpdateAppSliderMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateAppSliderMutation,
  CreateUpdateAppSliderMutationVariables
>;
export const DeleteAppSlidersDocument = gql`
  mutation DeleteAppSliders($ids: [String!]!) {
    adminDeleteAppSliders(ids: $ids)
  }
`;
export type DeleteAppSlidersMutationFn = Apollo.MutationFunction<
  DeleteAppSlidersMutation,
  DeleteAppSlidersMutationVariables
>;

/**
 * __useDeleteAppSlidersMutation__
 *
 * To run a mutation, you first call `useDeleteAppSlidersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppSlidersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppSlidersMutation, { data, loading, error }] = useDeleteAppSlidersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteAppSlidersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppSlidersMutation,
    DeleteAppSlidersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppSlidersMutation, DeleteAppSlidersMutationVariables>(
    DeleteAppSlidersDocument,
    options
  );
}
export type DeleteAppSlidersMutationHookResult = ReturnType<typeof useDeleteAppSlidersMutation>;
export type DeleteAppSlidersMutationResult = Apollo.MutationResult<DeleteAppSlidersMutation>;
export type DeleteAppSlidersMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppSlidersMutation,
  DeleteAppSlidersMutationVariables
>;
export const AdminLoginDocument = gql`
  mutation AdminLogin($input: AdminLoginInput!) {
    adminLogin(input: $input) {
      accessToken
      admin {
        ...AdminFragment
      }
      otpAuthUrl
    }
  }
  ${AdminFragmentFragmentDoc}
`;
export type AdminLoginMutationFn = Apollo.MutationFunction<
  AdminLoginMutation,
  AdminLoginMutationVariables
>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(
    AdminLoginDocument,
    options
  );
}
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<
  AdminLoginMutation,
  AdminLoginMutationVariables
>;
export const AdminDetailDocument = gql`
  query AdminDetail {
    adminMe {
      ...AdminFragment
    }
  }
  ${AdminFragmentFragmentDoc}
`;

/**
 * __useAdminDetailQuery__
 *
 * To run a query within a React component, call `useAdminDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminDetailQuery, AdminDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminDetailQuery, AdminDetailQueryVariables>(AdminDetailDocument, options);
}
export function useAdminDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminDetailQuery, AdminDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminDetailQuery, AdminDetailQueryVariables>(
    AdminDetailDocument,
    options
  );
}
export type AdminDetailQueryHookResult = ReturnType<typeof useAdminDetailQuery>;
export type AdminDetailLazyQueryHookResult = ReturnType<typeof useAdminDetailLazyQuery>;
export type AdminDetailQueryResult = Apollo.QueryResult<
  AdminDetailQuery,
  AdminDetailQueryVariables
>;
export const GetBankGatewaysDocument = gql`
  query GetBankGateways($limit: Int, $offset: Int, $order: SortOrder, $orderBy: String) {
    adminGetBankGateways(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      bankgateways {
        ...GatewayBankFragment
      }
      count
      limit
      offset
    }
  }
  ${GatewayBankFragmentFragmentDoc}
`;

/**
 * __useGetBankGatewaysQuery__
 *
 * To run a query within a React component, call `useGetBankGatewaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankGatewaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankGatewaysQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetBankGatewaysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBankGatewaysQuery, GetBankGatewaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBankGatewaysQuery, GetBankGatewaysQueryVariables>(
    GetBankGatewaysDocument,
    options
  );
}
export function useGetBankGatewaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankGatewaysQuery, GetBankGatewaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBankGatewaysQuery, GetBankGatewaysQueryVariables>(
    GetBankGatewaysDocument,
    options
  );
}
export type GetBankGatewaysQueryHookResult = ReturnType<typeof useGetBankGatewaysQuery>;
export type GetBankGatewaysLazyQueryHookResult = ReturnType<typeof useGetBankGatewaysLazyQuery>;
export type GetBankGatewaysQueryResult = Apollo.QueryResult<
  GetBankGatewaysQuery,
  GetBankGatewaysQueryVariables
>;
export const GetBankGatewayDocument = gql`
  query GetBankGateway($id: String!) {
    adminGetBankGateway(id: $id) {
      ...GatewayBankFragment
    }
  }
  ${GatewayBankFragmentFragmentDoc}
`;

/**
 * __useGetBankGatewayQuery__
 *
 * To run a query within a React component, call `useGetBankGatewayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankGatewayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankGatewayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBankGatewayQuery(
  baseOptions: Apollo.QueryHookOptions<GetBankGatewayQuery, GetBankGatewayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBankGatewayQuery, GetBankGatewayQueryVariables>(
    GetBankGatewayDocument,
    options
  );
}
export function useGetBankGatewayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankGatewayQuery, GetBankGatewayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBankGatewayQuery, GetBankGatewayQueryVariables>(
    GetBankGatewayDocument,
    options
  );
}
export type GetBankGatewayQueryHookResult = ReturnType<typeof useGetBankGatewayQuery>;
export type GetBankGatewayLazyQueryHookResult = ReturnType<typeof useGetBankGatewayLazyQuery>;
export type GetBankGatewayQueryResult = Apollo.QueryResult<
  GetBankGatewayQuery,
  GetBankGatewayQueryVariables
>;
export const CreateUpdateBankGatewayDocument = gql`
  mutation CreateUpdateBankGateway($input: AdminCreateUpdateBankGatewayInput!) {
    adminCreateUpdateBankGateway(input: $input) {
      ...GatewayBankFragment
    }
  }
  ${GatewayBankFragmentFragmentDoc}
`;
export type CreateUpdateBankGatewayMutationFn = Apollo.MutationFunction<
  CreateUpdateBankGatewayMutation,
  CreateUpdateBankGatewayMutationVariables
>;

/**
 * __useCreateUpdateBankGatewayMutation__
 *
 * To run a mutation, you first call `useCreateUpdateBankGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateBankGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateBankGatewayMutation, { data, loading, error }] = useCreateUpdateBankGatewayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateBankGatewayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateBankGatewayMutation,
    CreateUpdateBankGatewayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUpdateBankGatewayMutation,
    CreateUpdateBankGatewayMutationVariables
  >(CreateUpdateBankGatewayDocument, options);
}
export type CreateUpdateBankGatewayMutationHookResult = ReturnType<
  typeof useCreateUpdateBankGatewayMutation
>;
export type CreateUpdateBankGatewayMutationResult =
  Apollo.MutationResult<CreateUpdateBankGatewayMutation>;
export type CreateUpdateBankGatewayMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateBankGatewayMutation,
  CreateUpdateBankGatewayMutationVariables
>;
export const DeleteBankGatewaysDocument = gql`
  mutation DeleteBankGateways($ids: [String!]!) {
    adminDeleteBankGateways(ids: $ids)
  }
`;
export type DeleteBankGatewaysMutationFn = Apollo.MutationFunction<
  DeleteBankGatewaysMutation,
  DeleteBankGatewaysMutationVariables
>;

/**
 * __useDeleteBankGatewaysMutation__
 *
 * To run a mutation, you first call `useDeleteBankGatewaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankGatewaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankGatewaysMutation, { data, loading, error }] = useDeleteBankGatewaysMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteBankGatewaysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBankGatewaysMutation,
    DeleteBankGatewaysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBankGatewaysMutation, DeleteBankGatewaysMutationVariables>(
    DeleteBankGatewaysDocument,
    options
  );
}
export type DeleteBankGatewaysMutationHookResult = ReturnType<typeof useDeleteBankGatewaysMutation>;
export type DeleteBankGatewaysMutationResult = Apollo.MutationResult<DeleteBankGatewaysMutation>;
export type DeleteBankGatewaysMutationOptions = Apollo.BaseMutationOptions<
  DeleteBankGatewaysMutation,
  DeleteBankGatewaysMutationVariables
>;
export const GetDepositsDocument = gql`
  query GetDeposits($limit: Int, $offset: Int, $order: SortOrder, $orderBy: String) {
    adminGetUserDeposits(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      limit
      count
      offset
      userdeposits {
        ...ListDepositFragment
      }
    }
  }
  ${ListDepositFragmentFragmentDoc}
`;

/**
 * __useGetDepositsQuery__
 *
 * To run a query within a React component, call `useGetDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetDepositsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDepositsQuery, GetDepositsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDepositsQuery, GetDepositsQueryVariables>(GetDepositsDocument, options);
}
export function useGetDepositsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDepositsQuery, GetDepositsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDepositsQuery, GetDepositsQueryVariables>(
    GetDepositsDocument,
    options
  );
}
export type GetDepositsQueryHookResult = ReturnType<typeof useGetDepositsQuery>;
export type GetDepositsLazyQueryHookResult = ReturnType<typeof useGetDepositsLazyQuery>;
export type GetDepositsQueryResult = Apollo.QueryResult<
  GetDepositsQuery,
  GetDepositsQueryVariables
>;
export const GetDepositDocument = gql`
  query GetDeposit($id: String!) {
    adminGetUserDeposit(id: $id) {
      ...DepositFragment
    }
  }
  ${DepositFragmentFragmentDoc}
`;

/**
 * __useGetDepositQuery__
 *
 * To run a query within a React component, call `useGetDepositQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDepositQuery(
  baseOptions: Apollo.QueryHookOptions<GetDepositQuery, GetDepositQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDepositQuery, GetDepositQueryVariables>(GetDepositDocument, options);
}
export function useGetDepositLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDepositQuery, GetDepositQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDepositQuery, GetDepositQueryVariables>(
    GetDepositDocument,
    options
  );
}
export type GetDepositQueryHookResult = ReturnType<typeof useGetDepositQuery>;
export type GetDepositLazyQueryHookResult = ReturnType<typeof useGetDepositLazyQuery>;
export type GetDepositQueryResult = Apollo.QueryResult<GetDepositQuery, GetDepositQueryVariables>;
export const VerifyBankTransferDepositDocument = gql`
  mutation VerifyBankTransferDeposit($id: String!) {
    adminVerifyBankTransferDeposit(id: $id) {
      ...ListDepositFragment
    }
  }
  ${ListDepositFragmentFragmentDoc}
`;
export type VerifyBankTransferDepositMutationFn = Apollo.MutationFunction<
  VerifyBankTransferDepositMutation,
  VerifyBankTransferDepositMutationVariables
>;

/**
 * __useVerifyBankTransferDepositMutation__
 *
 * To run a mutation, you first call `useVerifyBankTransferDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyBankTransferDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyBankTransferDepositMutation, { data, loading, error }] = useVerifyBankTransferDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyBankTransferDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyBankTransferDepositMutation,
    VerifyBankTransferDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyBankTransferDepositMutation,
    VerifyBankTransferDepositMutationVariables
  >(VerifyBankTransferDepositDocument, options);
}
export type VerifyBankTransferDepositMutationHookResult = ReturnType<
  typeof useVerifyBankTransferDepositMutation
>;
export type VerifyBankTransferDepositMutationResult =
  Apollo.MutationResult<VerifyBankTransferDepositMutation>;
export type VerifyBankTransferDepositMutationOptions = Apollo.BaseMutationOptions<
  VerifyBankTransferDepositMutation,
  VerifyBankTransferDepositMutationVariables
>;
export const RejectDepositDocument = gql`
  mutation RejectDeposit($id: String!) {
    adminRejectDeposit(id: $id) {
      ...ListDepositFragment
    }
  }
  ${ListDepositFragmentFragmentDoc}
`;
export type RejectDepositMutationFn = Apollo.MutationFunction<
  RejectDepositMutation,
  RejectDepositMutationVariables
>;

/**
 * __useRejectDepositMutation__
 *
 * To run a mutation, you first call `useRejectDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDepositMutation, { data, loading, error }] = useRejectDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<RejectDepositMutation, RejectDepositMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectDepositMutation, RejectDepositMutationVariables>(
    RejectDepositDocument,
    options
  );
}
export type RejectDepositMutationHookResult = ReturnType<typeof useRejectDepositMutation>;
export type RejectDepositMutationResult = Apollo.MutationResult<RejectDepositMutation>;
export type RejectDepositMutationOptions = Apollo.BaseMutationOptions<
  RejectDepositMutation,
  RejectDepositMutationVariables
>;
export const GetExchangesDocument = gql`
  query GetExchanges($limit: Int, $offset: Int, $order: SortOrder, $orderBy: String) {
    adminGetConverts(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      limit
      count
      offset
      converts {
        ...ListExchangeFragment
      }
    }
  }
  ${ListExchangeFragmentFragmentDoc}
`;

/**
 * __useGetExchangesQuery__
 *
 * To run a query within a React component, call `useGetExchangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetExchangesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExchangesQuery, GetExchangesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangesQuery, GetExchangesQueryVariables>(
    GetExchangesDocument,
    options
  );
}
export function useGetExchangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExchangesQuery, GetExchangesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangesQuery, GetExchangesQueryVariables>(
    GetExchangesDocument,
    options
  );
}
export type GetExchangesQueryHookResult = ReturnType<typeof useGetExchangesQuery>;
export type GetExchangesLazyQueryHookResult = ReturnType<typeof useGetExchangesLazyQuery>;
export type GetExchangesQueryResult = Apollo.QueryResult<
  GetExchangesQuery,
  GetExchangesQueryVariables
>;
export const GetExchangeDocument = gql`
  query GetExchange($id: String!) {
    adminGetConvert(id: $id) {
      ...ExchangeFragment
    }
  }
  ${ExchangeFragmentFragmentDoc}
`;

/**
 * __useGetExchangeQuery__
 *
 * To run a query within a React component, call `useGetExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExchangeQuery(
  baseOptions: Apollo.QueryHookOptions<GetExchangeQuery, GetExchangeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeQuery, GetExchangeQueryVariables>(GetExchangeDocument, options);
}
export function useGetExchangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeQuery, GetExchangeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeQuery, GetExchangeQueryVariables>(
    GetExchangeDocument,
    options
  );
}
export type GetExchangeQueryHookResult = ReturnType<typeof useGetExchangeQuery>;
export type GetExchangeLazyQueryHookResult = ReturnType<typeof useGetExchangeLazyQuery>;
export type GetExchangeQueryResult = Apollo.QueryResult<
  GetExchangeQuery,
  GetExchangeQueryVariables
>;
export const SetSellBuyCompletedDocument = gql`
  mutation SetSellBuyCompleted($convertId: String!, $side: TradeSide!) {
    adminSetSellBuyCompleted(convertId: $convertId, side: $side)
  }
`;
export type SetSellBuyCompletedMutationFn = Apollo.MutationFunction<
  SetSellBuyCompletedMutation,
  SetSellBuyCompletedMutationVariables
>;

/**
 * __useSetSellBuyCompletedMutation__
 *
 * To run a mutation, you first call `useSetSellBuyCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSellBuyCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSellBuyCompletedMutation, { data, loading, error }] = useSetSellBuyCompletedMutation({
 *   variables: {
 *      convertId: // value for 'convertId'
 *      side: // value for 'side'
 *   },
 * });
 */
export function useSetSellBuyCompletedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSellBuyCompletedMutation,
    SetSellBuyCompletedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetSellBuyCompletedMutation, SetSellBuyCompletedMutationVariables>(
    SetSellBuyCompletedDocument,
    options
  );
}
export type SetSellBuyCompletedMutationHookResult = ReturnType<
  typeof useSetSellBuyCompletedMutation
>;
export type SetSellBuyCompletedMutationResult = Apollo.MutationResult<SetSellBuyCompletedMutation>;
export type SetSellBuyCompletedMutationOptions = Apollo.BaseMutationOptions<
  SetSellBuyCompletedMutation,
  SetSellBuyCompletedMutationVariables
>;
export const GetGatewaysDocument = gql`
  query GetGateways(
    $limit: Int
    $offset: Int
    $filter: GetGatewaysFilterInput
    $order: SortOrder
    $orderBy: String
  ) {
    adminGetGateways(
      limit: $limit
      offset: $offset
      filter: $filter
      order: $order
      orderBy: $orderBy
    ) {
      gateways {
        ...ListGatewayFragment
      }
      count
      limit
      offset
    }
  }
  ${ListGatewayFragmentFragmentDoc}
`;

/**
 * __useGetGatewaysQuery__
 *
 * To run a query within a React component, call `useGetGatewaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGatewaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGatewaysQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetGatewaysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGatewaysQuery, GetGatewaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGatewaysQuery, GetGatewaysQueryVariables>(GetGatewaysDocument, options);
}
export function useGetGatewaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGatewaysQuery, GetGatewaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGatewaysQuery, GetGatewaysQueryVariables>(
    GetGatewaysDocument,
    options
  );
}
export type GetGatewaysQueryHookResult = ReturnType<typeof useGetGatewaysQuery>;
export type GetGatewaysLazyQueryHookResult = ReturnType<typeof useGetGatewaysLazyQuery>;
export type GetGatewaysQueryResult = Apollo.QueryResult<
  GetGatewaysQuery,
  GetGatewaysQueryVariables
>;
export const GetGatewayDocument = gql`
  query GetGateway($id: String!) {
    adminGetGateway(id: $id) {
      ...GatewayFragment
    }
  }
  ${GatewayFragmentFragmentDoc}
`;

/**
 * __useGetGatewayQuery__
 *
 * To run a query within a React component, call `useGetGatewayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGatewayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGatewayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGatewayQuery(
  baseOptions: Apollo.QueryHookOptions<GetGatewayQuery, GetGatewayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGatewayQuery, GetGatewayQueryVariables>(GetGatewayDocument, options);
}
export function useGetGatewayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGatewayQuery, GetGatewayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGatewayQuery, GetGatewayQueryVariables>(
    GetGatewayDocument,
    options
  );
}
export type GetGatewayQueryHookResult = ReturnType<typeof useGetGatewayQuery>;
export type GetGatewayLazyQueryHookResult = ReturnType<typeof useGetGatewayLazyQuery>;
export type GetGatewayQueryResult = Apollo.QueryResult<GetGatewayQuery, GetGatewayQueryVariables>;
export const CreateUpdateGatewayDocument = gql`
  mutation CreateUpdateGateway($input: AdminCreateUpdateGatewayInput!, $file: Upload) {
    adminCreateUpdateGateway(input: $input, file: $file) {
      ...GatewayFragment
    }
  }
  ${GatewayFragmentFragmentDoc}
`;
export type CreateUpdateGatewayMutationFn = Apollo.MutationFunction<
  CreateUpdateGatewayMutation,
  CreateUpdateGatewayMutationVariables
>;

/**
 * __useCreateUpdateGatewayMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGatewayMutation, { data, loading, error }] = useCreateUpdateGatewayMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateUpdateGatewayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateGatewayMutation,
    CreateUpdateGatewayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUpdateGatewayMutation, CreateUpdateGatewayMutationVariables>(
    CreateUpdateGatewayDocument,
    options
  );
}
export type CreateUpdateGatewayMutationHookResult = ReturnType<
  typeof useCreateUpdateGatewayMutation
>;
export type CreateUpdateGatewayMutationResult = Apollo.MutationResult<CreateUpdateGatewayMutation>;
export type CreateUpdateGatewayMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateGatewayMutation,
  CreateUpdateGatewayMutationVariables
>;
export const DeleteGatewaysDocument = gql`
  mutation DeleteGateways($ids: [String!]!) {
    adminDeleteGateways(ids: $ids)
  }
`;
export type DeleteGatewaysMutationFn = Apollo.MutationFunction<
  DeleteGatewaysMutation,
  DeleteGatewaysMutationVariables
>;

/**
 * __useDeleteGatewaysMutation__
 *
 * To run a mutation, you first call `useDeleteGatewaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGatewaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGatewaysMutation, { data, loading, error }] = useDeleteGatewaysMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteGatewaysMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteGatewaysMutation, DeleteGatewaysMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGatewaysMutation, DeleteGatewaysMutationVariables>(
    DeleteGatewaysDocument,
    options
  );
}
export type DeleteGatewaysMutationHookResult = ReturnType<typeof useDeleteGatewaysMutation>;
export type DeleteGatewaysMutationResult = Apollo.MutationResult<DeleteGatewaysMutation>;
export type DeleteGatewaysMutationOptions = Apollo.BaseMutationOptions<
  DeleteGatewaysMutation,
  DeleteGatewaysMutationVariables
>;
export const GetMarketsDocument = gql`
  query GetMarkets(
    $limit: Int
    $offset: Int
    $filter: GetMarketsFilterInput
    $order: SortOrder
    $orderBy: String
  ) {
    adminGetMarkets(
      limit: $limit
      offset: $offset
      filter: $filter
      order: $order
      orderBy: $orderBy
    ) {
      limit
      count
      offset
      markets {
        id
        channel
        isActive
        type
        createdAt
      }
    }
  }
`;

/**
 * __useGetMarketsQuery__
 *
 * To run a query within a React component, call `useGetMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetMarketsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMarketsQuery, GetMarketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMarketsQuery, GetMarketsQueryVariables>(GetMarketsDocument, options);
}
export function useGetMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMarketsQuery, GetMarketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMarketsQuery, GetMarketsQueryVariables>(
    GetMarketsDocument,
    options
  );
}
export type GetMarketsQueryHookResult = ReturnType<typeof useGetMarketsQuery>;
export type GetMarketsLazyQueryHookResult = ReturnType<typeof useGetMarketsLazyQuery>;
export type GetMarketsQueryResult = Apollo.QueryResult<GetMarketsQuery, GetMarketsQueryVariables>;
export const GetMarketDocument = gql`
  query GetMarket($id: String!) {
    adminGetMarket(id: $id) {
      id
      channel
      fromGateway {
        id
        name
        persianName
        currency
      }
      toGateway {
        id
        name
        persianName
        currency
      }
      type
      isActive
    }
  }
`;

/**
 * __useGetMarketQuery__
 *
 * To run a query within a React component, call `useGetMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMarketQuery(
  baseOptions: Apollo.QueryHookOptions<GetMarketQuery, GetMarketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMarketQuery, GetMarketQueryVariables>(GetMarketDocument, options);
}
export function useGetMarketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMarketQuery, GetMarketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMarketQuery, GetMarketQueryVariables>(GetMarketDocument, options);
}
export type GetMarketQueryHookResult = ReturnType<typeof useGetMarketQuery>;
export type GetMarketLazyQueryHookResult = ReturnType<typeof useGetMarketLazyQuery>;
export type GetMarketQueryResult = Apollo.QueryResult<GetMarketQuery, GetMarketQueryVariables>;
export const CreateUpdateMarketDocument = gql`
  mutation CreateUpdateMarket($input: AdminCreateUpdateMarketInput!) {
    adminCreateUpdateMarket(input: $input) {
      id
      channel
    }
  }
`;
export type CreateUpdateMarketMutationFn = Apollo.MutationFunction<
  CreateUpdateMarketMutation,
  CreateUpdateMarketMutationVariables
>;

/**
 * __useCreateUpdateMarketMutation__
 *
 * To run a mutation, you first call `useCreateUpdateMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateMarketMutation, { data, loading, error }] = useCreateUpdateMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateMarketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateMarketMutation,
    CreateUpdateMarketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUpdateMarketMutation, CreateUpdateMarketMutationVariables>(
    CreateUpdateMarketDocument,
    options
  );
}
export type CreateUpdateMarketMutationHookResult = ReturnType<typeof useCreateUpdateMarketMutation>;
export type CreateUpdateMarketMutationResult = Apollo.MutationResult<CreateUpdateMarketMutation>;
export type CreateUpdateMarketMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateMarketMutation,
  CreateUpdateMarketMutationVariables
>;
export const DeleteMarketsDocument = gql`
  mutation DeleteMarkets($ids: [String!]!) {
    adminDeleteMarkets(ids: $ids)
  }
`;
export type DeleteMarketsMutationFn = Apollo.MutationFunction<
  DeleteMarketsMutation,
  DeleteMarketsMutationVariables
>;

/**
 * __useDeleteMarketsMutation__
 *
 * To run a mutation, you first call `useDeleteMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMarketsMutation, { data, loading, error }] = useDeleteMarketsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMarketsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMarketsMutation, DeleteMarketsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMarketsMutation, DeleteMarketsMutationVariables>(
    DeleteMarketsDocument,
    options
  );
}
export type DeleteMarketsMutationHookResult = ReturnType<typeof useDeleteMarketsMutation>;
export type DeleteMarketsMutationResult = Apollo.MutationResult<DeleteMarketsMutation>;
export type DeleteMarketsMutationOptions = Apollo.BaseMutationOptions<
  DeleteMarketsMutation,
  DeleteMarketsMutationVariables
>;
export const GetNetworksDocument = gql`
  query GetNetworks(
    $limit: Int!
    $offset: Int!
    $filter: AdminGetNetworksFilterInput
    $order: SortOrder
    $orderBy: String
  ) {
    adminGetNetworks(
      limit: $limit
      offset: $offset
      filter: $filter
      order: $order
      orderBy: $orderBy
    ) {
      networks {
        ...ListNetworkFragment
      }
      count
      limit
      offset
    }
  }
  ${ListNetworkFragmentFragmentDoc}
`;

/**
 * __useGetNetworksQuery__
 *
 * To run a query within a React component, call `useGetNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetNetworksQuery(
  baseOptions: Apollo.QueryHookOptions<GetNetworksQuery, GetNetworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNetworksQuery, GetNetworksQueryVariables>(GetNetworksDocument, options);
}
export function useGetNetworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNetworksQuery, GetNetworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNetworksQuery, GetNetworksQueryVariables>(
    GetNetworksDocument,
    options
  );
}
export type GetNetworksQueryHookResult = ReturnType<typeof useGetNetworksQuery>;
export type GetNetworksLazyQueryHookResult = ReturnType<typeof useGetNetworksLazyQuery>;
export type GetNetworksQueryResult = Apollo.QueryResult<
  GetNetworksQuery,
  GetNetworksQueryVariables
>;
export const GetNetworkDocument = gql`
  query GetNetwork($id: String!) {
    adminGetNetwork(id: $id) {
      ...NetworkFragment
    }
  }
  ${NetworkFragmentFragmentDoc}
`;

/**
 * __useGetNetworkQuery__
 *
 * To run a query within a React component, call `useGetNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNetworkQuery(
  baseOptions: Apollo.QueryHookOptions<GetNetworkQuery, GetNetworkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNetworkQuery, GetNetworkQueryVariables>(GetNetworkDocument, options);
}
export function useGetNetworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNetworkQuery, GetNetworkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNetworkQuery, GetNetworkQueryVariables>(
    GetNetworkDocument,
    options
  );
}
export type GetNetworkQueryHookResult = ReturnType<typeof useGetNetworkQuery>;
export type GetNetworkLazyQueryHookResult = ReturnType<typeof useGetNetworkLazyQuery>;
export type GetNetworkQueryResult = Apollo.QueryResult<GetNetworkQuery, GetNetworkQueryVariables>;
export const DeleteNetworksDocument = gql`
  mutation DeleteNetworks($ids: [String!]!) {
    adminDeleteNetworks(ids: $ids)
  }
`;
export type DeleteNetworksMutationFn = Apollo.MutationFunction<
  DeleteNetworksMutation,
  DeleteNetworksMutationVariables
>;

/**
 * __useDeleteNetworksMutation__
 *
 * To run a mutation, you first call `useDeleteNetworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNetworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNetworksMutation, { data, loading, error }] = useDeleteNetworksMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteNetworksMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteNetworksMutation, DeleteNetworksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNetworksMutation, DeleteNetworksMutationVariables>(
    DeleteNetworksDocument,
    options
  );
}
export type DeleteNetworksMutationHookResult = ReturnType<typeof useDeleteNetworksMutation>;
export type DeleteNetworksMutationResult = Apollo.MutationResult<DeleteNetworksMutation>;
export type DeleteNetworksMutationOptions = Apollo.BaseMutationOptions<
  DeleteNetworksMutation,
  DeleteNetworksMutationVariables
>;
export const GetRatesDocument = gql`
  query GetRates(
    $limit: Int!
    $offset: Int!
    $filter: AdminGetRatesFilterInput
    $order: SortOrder
    $orderBy: String
  ) {
    adminGetRates(
      limit: $limit
      offset: $offset
      filter: $filter
      order: $order
      orderBy: $orderBy
    ) {
      rates {
        ...ListRateFragment
      }
      count
      limit
      offset
    }
  }
  ${ListRateFragmentFragmentDoc}
`;

/**
 * __useGetRatesQuery__
 *
 * To run a query within a React component, call `useGetRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetRatesQuery(
  baseOptions: Apollo.QueryHookOptions<GetRatesQuery, GetRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRatesQuery, GetRatesQueryVariables>(GetRatesDocument, options);
}
export function useGetRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRatesQuery, GetRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRatesQuery, GetRatesQueryVariables>(GetRatesDocument, options);
}
export type GetRatesQueryHookResult = ReturnType<typeof useGetRatesQuery>;
export type GetRatesLazyQueryHookResult = ReturnType<typeof useGetRatesLazyQuery>;
export type GetRatesQueryResult = Apollo.QueryResult<GetRatesQuery, GetRatesQueryVariables>;
export const GetRateDocument = gql`
  query GetRate($id: String!) {
    adminGetRate(id: $id) {
      ...RateFragment
    }
  }
  ${RateFragmentFragmentDoc}
`;

/**
 * __useGetRateQuery__
 *
 * To run a query within a React component, call `useGetRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRateQuery(
  baseOptions: Apollo.QueryHookOptions<GetRateQuery, GetRateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRateQuery, GetRateQueryVariables>(GetRateDocument, options);
}
export function useGetRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRateQuery, GetRateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRateQuery, GetRateQueryVariables>(GetRateDocument, options);
}
export type GetRateQueryHookResult = ReturnType<typeof useGetRateQuery>;
export type GetRateLazyQueryHookResult = ReturnType<typeof useGetRateLazyQuery>;
export type GetRateQueryResult = Apollo.QueryResult<GetRateQuery, GetRateQueryVariables>;
export const CreateUpdateRateDocument = gql`
  mutation CreateUpdateRate($input: AdminCreateUpdateRateInput!) {
    adminCreateUpdateRate(input: $input) {
      ...RateFragment
    }
  }
  ${RateFragmentFragmentDoc}
`;
export type CreateUpdateRateMutationFn = Apollo.MutationFunction<
  CreateUpdateRateMutation,
  CreateUpdateRateMutationVariables
>;

/**
 * __useCreateUpdateRateMutation__
 *
 * To run a mutation, you first call `useCreateUpdateRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateRateMutation, { data, loading, error }] = useCreateUpdateRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateRateMutation,
    CreateUpdateRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUpdateRateMutation, CreateUpdateRateMutationVariables>(
    CreateUpdateRateDocument,
    options
  );
}
export type CreateUpdateRateMutationHookResult = ReturnType<typeof useCreateUpdateRateMutation>;
export type CreateUpdateRateMutationResult = Apollo.MutationResult<CreateUpdateRateMutation>;
export type CreateUpdateRateMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateRateMutation,
  CreateUpdateRateMutationVariables
>;
export const DeleteRatesDocument = gql`
  mutation DeleteRates($ids: [String!]!) {
    adminDeleteRates(ids: $ids)
  }
`;
export type DeleteRatesMutationFn = Apollo.MutationFunction<
  DeleteRatesMutation,
  DeleteRatesMutationVariables
>;

/**
 * __useDeleteRatesMutation__
 *
 * To run a mutation, you first call `useDeleteRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRatesMutation, { data, loading, error }] = useDeleteRatesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteRatesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRatesMutation, DeleteRatesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRatesMutation, DeleteRatesMutationVariables>(
    DeleteRatesDocument,
    options
  );
}
export type DeleteRatesMutationHookResult = ReturnType<typeof useDeleteRatesMutation>;
export type DeleteRatesMutationResult = Apollo.MutationResult<DeleteRatesMutation>;
export type DeleteRatesMutationOptions = Apollo.BaseMutationOptions<
  DeleteRatesMutation,
  DeleteRatesMutationVariables
>;
export const GetSettingsDocument = gql`
  query GetSettings {
    adminGetSettings {
      ...SettingFragment
    }
  }
  ${SettingFragmentFragmentDoc}
`;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
}
export function useGetSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(
    GetSettingsDocument,
    options
  );
}
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = Apollo.QueryResult<
  GetSettingsQuery,
  GetSettingsQueryVariables
>;
export const CreateUpdateSettingDocument = gql`
  mutation CreateUpdateSetting($input: AdminCreateUpdateSettingInput!) {
    adminCreateUpdateSetting(input: $input) {
      ...SettingFragment
    }
  }
  ${SettingFragmentFragmentDoc}
`;
export type CreateUpdateSettingMutationFn = Apollo.MutationFunction<
  CreateUpdateSettingMutation,
  CreateUpdateSettingMutationVariables
>;

/**
 * __useCreateUpdateSettingMutation__
 *
 * To run a mutation, you first call `useCreateUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateSettingMutation, { data, loading, error }] = useCreateUpdateSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateSettingMutation,
    CreateUpdateSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUpdateSettingMutation, CreateUpdateSettingMutationVariables>(
    CreateUpdateSettingDocument,
    options
  );
}
export type CreateUpdateSettingMutationHookResult = ReturnType<
  typeof useCreateUpdateSettingMutation
>;
export type CreateUpdateSettingMutationResult = Apollo.MutationResult<CreateUpdateSettingMutation>;
export type CreateUpdateSettingMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateSettingMutation,
  CreateUpdateSettingMutationVariables
>;
export const GetTicketsDocument = gql`
  query GetTickets($limit: Int, $offset: Int, $order: SortOrder, $orderBy: String) {
    adminGetTickets(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      limit
      count
      offset
      tickets {
        ...ListTicketFragment
      }
    }
  }
  ${ListTicketFragmentFragmentDoc}
`;

/**
 * __useGetTicketsQuery__
 *
 * To run a query within a React component, call `useGetTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, options);
}
export function useGetTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTicketsQuery, GetTicketsQueryVariables>(
    GetTicketsDocument,
    options
  );
}
export type GetTicketsQueryHookResult = ReturnType<typeof useGetTicketsQuery>;
export type GetTicketsLazyQueryHookResult = ReturnType<typeof useGetTicketsLazyQuery>;
export type GetTicketsQueryResult = Apollo.QueryResult<GetTicketsQuery, GetTicketsQueryVariables>;
export const GetTicketDocument = gql`
  query GetTicket($id: String!) {
    adminGetTicket(id: $id) {
      id
      reference
      title
      status
      createdAt
      updatedAt
      messages {
        id
        fileLink
        content
        userId
        user {
          firstName
          lastName
        }
        adminId
        admin {
          firstName
          lastName
        }
        createdAt
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetTicketQuery__
 *
 * To run a query within a React component, call `useGetTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTicketQuery(
  baseOptions: Apollo.QueryHookOptions<GetTicketQuery, GetTicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketQuery, GetTicketQueryVariables>(GetTicketDocument, options);
}
export function useGetTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTicketQuery, GetTicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTicketQuery, GetTicketQueryVariables>(GetTicketDocument, options);
}
export type GetTicketQueryHookResult = ReturnType<typeof useGetTicketQuery>;
export type GetTicketLazyQueryHookResult = ReturnType<typeof useGetTicketLazyQuery>;
export type GetTicketQueryResult = Apollo.QueryResult<GetTicketQuery, GetTicketQueryVariables>;
export const AddMessageToTicketDocument = gql`
  mutation AddMessageToTicket($input: AddMessageToTicket!, $file: Upload) {
    adminAddMessageToTicket(input: $input, file: $file) {
      id
    }
  }
`;
export type AddMessageToTicketMutationFn = Apollo.MutationFunction<
  AddMessageToTicketMutation,
  AddMessageToTicketMutationVariables
>;

/**
 * __useAddMessageToTicketMutation__
 *
 * To run a mutation, you first call `useAddMessageToTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageToTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageToTicketMutation, { data, loading, error }] = useAddMessageToTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddMessageToTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMessageToTicketMutation,
    AddMessageToTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMessageToTicketMutation, AddMessageToTicketMutationVariables>(
    AddMessageToTicketDocument,
    options
  );
}
export type AddMessageToTicketMutationHookResult = ReturnType<typeof useAddMessageToTicketMutation>;
export type AddMessageToTicketMutationResult = Apollo.MutationResult<AddMessageToTicketMutation>;
export type AddMessageToTicketMutationOptions = Apollo.BaseMutationOptions<
  AddMessageToTicketMutation,
  AddMessageToTicketMutationVariables
>;
export const UpdateTicketStatusDocument = gql`
  mutation UpdateTicketStatus($input: AdminUpdateTicketStatusInput!) {
    adminUpdateTicketStatus(input: $input) {
      id
    }
  }
`;
export type UpdateTicketStatusMutationFn = Apollo.MutationFunction<
  UpdateTicketStatusMutation,
  UpdateTicketStatusMutationVariables
>;

/**
 * __useUpdateTicketStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTicketStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketStatusMutation, { data, loading, error }] = useUpdateTicketStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTicketStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTicketStatusMutation,
    UpdateTicketStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTicketStatusMutation, UpdateTicketStatusMutationVariables>(
    UpdateTicketStatusDocument,
    options
  );
}
export type UpdateTicketStatusMutationHookResult = ReturnType<typeof useUpdateTicketStatusMutation>;
export type UpdateTicketStatusMutationResult = Apollo.MutationResult<UpdateTicketStatusMutation>;
export type UpdateTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketStatusMutation,
  UpdateTicketStatusMutationVariables
>;
export const GetTradesDocument = gql`
  query GetTrades($limit: Int, $offset: Int, $order: SortOrder, $orderBy: String) {
    adminGetTrades(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      limit
      count
      offset
      trades {
        ...ListTradeFragment
      }
    }
  }
  ${ListTradeFragmentFragmentDoc}
`;

/**
 * __useGetTradesQuery__
 *
 * To run a query within a React component, call `useGetTradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTradesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTradesQuery, GetTradesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTradesQuery, GetTradesQueryVariables>(GetTradesDocument, options);
}
export function useGetTradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTradesQuery, GetTradesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTradesQuery, GetTradesQueryVariables>(GetTradesDocument, options);
}
export type GetTradesQueryHookResult = ReturnType<typeof useGetTradesQuery>;
export type GetTradesLazyQueryHookResult = ReturnType<typeof useGetTradesLazyQuery>;
export type GetTradesQueryResult = Apollo.QueryResult<GetTradesQuery, GetTradesQueryVariables>;
export const GetTradeDocument = gql`
  query GetTrade($id: String!) {
    adminGetTrade(id: $id) {
      ...TradeFragment
    }
  }
  ${TradeFragmentFragmentDoc}
`;

/**
 * __useGetTradeQuery__
 *
 * To run a query within a React component, call `useGetTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTradeQuery(
  baseOptions: Apollo.QueryHookOptions<GetTradeQuery, GetTradeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTradeQuery, GetTradeQueryVariables>(GetTradeDocument, options);
}
export function useGetTradeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTradeQuery, GetTradeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTradeQuery, GetTradeQueryVariables>(GetTradeDocument, options);
}
export type GetTradeQueryHookResult = ReturnType<typeof useGetTradeQuery>;
export type GetTradeLazyQueryHookResult = ReturnType<typeof useGetTradeLazyQuery>;
export type GetTradeQueryResult = Apollo.QueryResult<GetTradeQuery, GetTradeQueryVariables>;
export const GetTransactionsDocument = gql`
  query GetTransactions($limit: Int!, $offset: Int!, $order: SortOrder, $orderBy: String) {
    adminGetTransactions(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      transactions {
        ...ListTransactionFragment
      }
      count
      limit
      offset
    }
  }
  ${ListTransactionFragmentFragmentDoc}
`;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(
    GetTransactionsDocument,
    options
  );
}
export function useGetTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(
    GetTransactionsDocument,
    options
  );
}
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<
  GetTransactionsQuery,
  GetTransactionsQueryVariables
>;
export const GetTransactionDocument = gql`
  query GetTransaction($id: String!) {
    adminGetTransaction(id: $id) {
      ...TransactionFragment
    }
  }
  ${TransactionFragmentFragmentDoc}
`;

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(
    GetTransactionDocument,
    options
  );
}
export function useGetTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(
    GetTransactionDocument,
    options
  );
}
export type GetTransactionQueryHookResult = ReturnType<typeof useGetTransactionQuery>;
export type GetTransactionLazyQueryHookResult = ReturnType<typeof useGetTransactionLazyQuery>;
export type GetTransactionQueryResult = Apollo.QueryResult<
  GetTransactionQuery,
  GetTransactionQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers(
    $limit: Int!
    $offset: Int!
    $filter: AdminGetUsersFilterInput
    $order: SortOrder
    $orderBy: String
  ) {
    adminGetUsers(
      limit: $limit
      offset: $offset
      filter: $filter
      order: $order
      orderBy: $orderBy
    ) {
      users {
        ...ListUserFragment
      }
      count
      limit
      offset
    }
  }
  ${ListUserFragmentFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
  query GetUser($id: String!) {
    adminGetUser(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateUpdateUserDocument = gql`
  mutation CreateUpdateUser($input: AdminCreateUpdateUserInput!) {
    adminCreateUpdateUser(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateUpdateUserMutationFn = Apollo.MutationFunction<
  CreateUpdateUserMutation,
  CreateUpdateUserMutationVariables
>;

/**
 * __useCreateUpdateUserMutation__
 *
 * To run a mutation, you first call `useCreateUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateUserMutation, { data, loading, error }] = useCreateUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateUserMutation,
    CreateUpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUpdateUserMutation, CreateUpdateUserMutationVariables>(
    CreateUpdateUserDocument,
    options
  );
}
export type CreateUpdateUserMutationHookResult = ReturnType<typeof useCreateUpdateUserMutation>;
export type CreateUpdateUserMutationResult = Apollo.MutationResult<CreateUpdateUserMutation>;
export type CreateUpdateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateUserMutation,
  CreateUpdateUserMutationVariables
>;
export const CreateUpdateUserWalletDocument = gql`
  mutation CreateUpdateUserWallet($input: AdminCreditDebitUserWalletInput!) {
    adminCreditDebitUserWallet(input: $input) {
      id
    }
  }
`;
export type CreateUpdateUserWalletMutationFn = Apollo.MutationFunction<
  CreateUpdateUserWalletMutation,
  CreateUpdateUserWalletMutationVariables
>;

/**
 * __useCreateUpdateUserWalletMutation__
 *
 * To run a mutation, you first call `useCreateUpdateUserWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateUserWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateUserWalletMutation, { data, loading, error }] = useCreateUpdateUserWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateUserWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateUserWalletMutation,
    CreateUpdateUserWalletMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUpdateUserWalletMutation,
    CreateUpdateUserWalletMutationVariables
  >(CreateUpdateUserWalletDocument, options);
}
export type CreateUpdateUserWalletMutationHookResult = ReturnType<
  typeof useCreateUpdateUserWalletMutation
>;
export type CreateUpdateUserWalletMutationResult =
  Apollo.MutationResult<CreateUpdateUserWalletMutation>;
export type CreateUpdateUserWalletMutationOptions = Apollo.BaseMutationOptions<
  CreateUpdateUserWalletMutation,
  CreateUpdateUserWalletMutationVariables
>;
export const DeleteUsersDocument = gql`
  mutation DeleteUsers($ids: [String!]!) {
    adminDeleteUsers(ids: $ids)
  }
`;
export type DeleteUsersMutationFn = Apollo.MutationFunction<
  DeleteUsersMutation,
  DeleteUsersMutationVariables
>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(
    DeleteUsersDocument,
    options
  );
}
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<
  DeleteUsersMutation,
  DeleteUsersMutationVariables
>;
export const DeleteUserWalletsDocument = gql`
  mutation DeleteUserWallets($ids: [String!]!) {
    adminDeleteUserWallets(ids: $ids)
  }
`;
export type DeleteUserWalletsMutationFn = Apollo.MutationFunction<
  DeleteUserWalletsMutation,
  DeleteUserWalletsMutationVariables
>;

/**
 * __useDeleteUserWalletsMutation__
 *
 * To run a mutation, you first call `useDeleteUserWalletsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserWalletsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserWalletsMutation, { data, loading, error }] = useDeleteUserWalletsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUserWalletsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserWalletsMutation,
    DeleteUserWalletsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserWalletsMutation, DeleteUserWalletsMutationVariables>(
    DeleteUserWalletsDocument,
    options
  );
}
export type DeleteUserWalletsMutationHookResult = ReturnType<typeof useDeleteUserWalletsMutation>;
export type DeleteUserWalletsMutationResult = Apollo.MutationResult<DeleteUserWalletsMutation>;
export type DeleteUserWalletsMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserWalletsMutation,
  DeleteUserWalletsMutationVariables
>;
export const GetUserBankAccountsDocument = gql`
  query GetUserBankAccounts(
    $limit: Int!
    $offset: Int!
    $filter: AdminGetBankAccountsFilterInput
    $order: SortOrder
    $orderBy: String
  ) {
    adminGetBankAccounts(
      limit: $limit
      offset: $offset
      filter: $filter
      order: $order
      orderBy: $orderBy
    ) {
      bankaccounts {
        ...ListUserBankAccountFragment
      }
      count
      limit
      offset
    }
  }
  ${ListUserBankAccountFragmentFragmentDoc}
`;

/**
 * __useGetUserBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetUserBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBankAccountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUserBankAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>(
    GetUserBankAccountsDocument,
    options
  );
}
export function useGetUserBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBankAccountsQuery,
    GetUserBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>(
    GetUserBankAccountsDocument,
    options
  );
}
export type GetUserBankAccountsQueryHookResult = ReturnType<typeof useGetUserBankAccountsQuery>;
export type GetUserBankAccountsLazyQueryHookResult = ReturnType<
  typeof useGetUserBankAccountsLazyQuery
>;
export type GetUserBankAccountsQueryResult = Apollo.QueryResult<
  GetUserBankAccountsQuery,
  GetUserBankAccountsQueryVariables
>;
export const GetUserBankAccountDocument = gql`
  query GetUserBankAccount($id: String!) {
    adminGetBankAccount(id: $id) {
      ...UserBankAccountFragment
    }
  }
  ${UserBankAccountFragmentFragmentDoc}
`;

/**
 * __useGetUserBankAccountQuery__
 *
 * To run a query within a React component, call `useGetUserBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBankAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserBankAccountQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserBankAccountQuery, GetUserBankAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserBankAccountQuery, GetUserBankAccountQueryVariables>(
    GetUserBankAccountDocument,
    options
  );
}
export function useGetUserBankAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBankAccountQuery,
    GetUserBankAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserBankAccountQuery, GetUserBankAccountQueryVariables>(
    GetUserBankAccountDocument,
    options
  );
}
export type GetUserBankAccountQueryHookResult = ReturnType<typeof useGetUserBankAccountQuery>;
export type GetUserBankAccountLazyQueryHookResult = ReturnType<
  typeof useGetUserBankAccountLazyQuery
>;
export type GetUserBankAccountQueryResult = Apollo.QueryResult<
  GetUserBankAccountQuery,
  GetUserBankAccountQueryVariables
>;
export const UpdateUserBankAccountDocument = gql`
  mutation UpdateUserBankAccount($input: AdminUpdateBankAccountInput!) {
    adminUpdateBankAccount(input: $input) {
      ...UserBankAccountFragment
    }
  }
  ${UserBankAccountFragmentFragmentDoc}
`;
export type UpdateUserBankAccountMutationFn = Apollo.MutationFunction<
  UpdateUserBankAccountMutation,
  UpdateUserBankAccountMutationVariables
>;

/**
 * __useUpdateUserBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBankAccountMutation, { data, loading, error }] = useUpdateUserBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserBankAccountMutation,
    UpdateUserBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserBankAccountMutation, UpdateUserBankAccountMutationVariables>(
    UpdateUserBankAccountDocument,
    options
  );
}
export type UpdateUserBankAccountMutationHookResult = ReturnType<
  typeof useUpdateUserBankAccountMutation
>;
export type UpdateUserBankAccountMutationResult =
  Apollo.MutationResult<UpdateUserBankAccountMutation>;
export type UpdateUserBankAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserBankAccountMutation,
  UpdateUserBankAccountMutationVariables
>;
export const DeleteUserBankAccountsDocument = gql`
  mutation DeleteUserBankAccounts($ids: [String!]!) {
    adminDeleteBankAccounts(ids: $ids)
  }
`;
export type DeleteUserBankAccountsMutationFn = Apollo.MutationFunction<
  DeleteUserBankAccountsMutation,
  DeleteUserBankAccountsMutationVariables
>;

/**
 * __useDeleteUserBankAccountsMutation__
 *
 * To run a mutation, you first call `useDeleteUserBankAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBankAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBankAccountsMutation, { data, loading, error }] = useDeleteUserBankAccountsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUserBankAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserBankAccountsMutation,
    DeleteUserBankAccountsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserBankAccountsMutation,
    DeleteUserBankAccountsMutationVariables
  >(DeleteUserBankAccountsDocument, options);
}
export type DeleteUserBankAccountsMutationHookResult = ReturnType<
  typeof useDeleteUserBankAccountsMutation
>;
export type DeleteUserBankAccountsMutationResult =
  Apollo.MutationResult<DeleteUserBankAccountsMutation>;
export type DeleteUserBankAccountsMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserBankAccountsMutation,
  DeleteUserBankAccountsMutationVariables
>;
export const GetUserDocumentsDocument = gql`
  query GetUserDocuments($limit: Int, $offset: Int, $order: SortOrder, $orderBy: String) {
    adminGetUserDocuments(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      userdocuments {
        ...ListDocumentFragment
      }
      count
      limit
      offset
    }
  }
  ${ListDocumentFragmentFragmentDoc}
`;

/**
 * __useGetUserDocumentsQuery__
 *
 * To run a query within a React component, call `useGetUserDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUserDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>(
    GetUserDocumentsDocument,
    options
  );
}
export function useGetUserDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>(
    GetUserDocumentsDocument,
    options
  );
}
export type GetUserDocumentsQueryHookResult = ReturnType<typeof useGetUserDocumentsQuery>;
export type GetUserDocumentsLazyQueryHookResult = ReturnType<typeof useGetUserDocumentsLazyQuery>;
export type GetUserDocumentsQueryResult = Apollo.QueryResult<
  GetUserDocumentsQuery,
  GetUserDocumentsQueryVariables
>;
export const GetUserDocumentDocument = gql`
  query GetUserDocument($id: String!) {
    adminGetUserDocument(id: $id) {
      ...ListDocumentFragment
    }
  }
  ${ListDocumentFragmentFragmentDoc}
`;

/**
 * __useGetUserDocumentQuery__
 *
 * To run a query within a React component, call `useGetUserDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserDocumentQuery, GetUserDocumentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDocumentQuery, GetUserDocumentQueryVariables>(
    GetUserDocumentDocument,
    options
  );
}
export function useGetUserDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserDocumentQuery, GetUserDocumentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDocumentQuery, GetUserDocumentQueryVariables>(
    GetUserDocumentDocument,
    options
  );
}
export type GetUserDocumentQueryHookResult = ReturnType<typeof useGetUserDocumentQuery>;
export type GetUserDocumentLazyQueryHookResult = ReturnType<typeof useGetUserDocumentLazyQuery>;
export type GetUserDocumentQueryResult = Apollo.QueryResult<
  GetUserDocumentQuery,
  GetUserDocumentQueryVariables
>;
export const UpdateUserDocumentStatusDocument = gql`
  mutation UpdateUserDocumentStatus($input: AdminUpdateUserDocumentStatusInput!) {
    adminUpdateUserDocumentStatus(input: $input) {
      id
    }
  }
`;
export type UpdateUserDocumentStatusMutationFn = Apollo.MutationFunction<
  UpdateUserDocumentStatusMutation,
  UpdateUserDocumentStatusMutationVariables
>;

/**
 * __useUpdateUserDocumentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserDocumentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDocumentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDocumentStatusMutation, { data, loading, error }] = useUpdateUserDocumentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserDocumentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDocumentStatusMutation,
    UpdateUserDocumentStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserDocumentStatusMutation,
    UpdateUserDocumentStatusMutationVariables
  >(UpdateUserDocumentStatusDocument, options);
}
export type UpdateUserDocumentStatusMutationHookResult = ReturnType<
  typeof useUpdateUserDocumentStatusMutation
>;
export type UpdateUserDocumentStatusMutationResult =
  Apollo.MutationResult<UpdateUserDocumentStatusMutation>;
export type UpdateUserDocumentStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDocumentStatusMutation,
  UpdateUserDocumentStatusMutationVariables
>;
export const DeleteUserDocumentsDocument = gql`
  mutation DeleteUserDocuments($ids: [String!]!) {
    adminDeleteUserDocuments(ids: $ids)
  }
`;
export type DeleteUserDocumentsMutationFn = Apollo.MutationFunction<
  DeleteUserDocumentsMutation,
  DeleteUserDocumentsMutationVariables
>;

/**
 * __useDeleteUserDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteUserDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDocumentsMutation, { data, loading, error }] = useDeleteUserDocumentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUserDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserDocumentsMutation,
    DeleteUserDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserDocumentsMutation, DeleteUserDocumentsMutationVariables>(
    DeleteUserDocumentsDocument,
    options
  );
}
export type DeleteUserDocumentsMutationHookResult = ReturnType<
  typeof useDeleteUserDocumentsMutation
>;
export type DeleteUserDocumentsMutationResult = Apollo.MutationResult<DeleteUserDocumentsMutation>;
export type DeleteUserDocumentsMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserDocumentsMutation,
  DeleteUserDocumentsMutationVariables
>;
export const GetWalletsWithBalanceDocument = gql`
  query GetWalletsWithBalance {
    adminGetAllGatewaysWithWallet {
      id
      name
      currency
      price
      totalAmount
    }
  }
`;

/**
 * __useGetWalletsWithBalanceQuery__
 *
 * To run a query within a React component, call `useGetWalletsWithBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletsWithBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletsWithBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletsWithBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWalletsWithBalanceQuery,
    GetWalletsWithBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWalletsWithBalanceQuery, GetWalletsWithBalanceQueryVariables>(
    GetWalletsWithBalanceDocument,
    options
  );
}
export function useGetWalletsWithBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletsWithBalanceQuery,
    GetWalletsWithBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWalletsWithBalanceQuery, GetWalletsWithBalanceQueryVariables>(
    GetWalletsWithBalanceDocument,
    options
  );
}
export type GetWalletsWithBalanceQueryHookResult = ReturnType<typeof useGetWalletsWithBalanceQuery>;
export type GetWalletsWithBalanceLazyQueryHookResult = ReturnType<
  typeof useGetWalletsWithBalanceLazyQuery
>;
export type GetWalletsWithBalanceQueryResult = Apollo.QueryResult<
  GetWalletsWithBalanceQuery,
  GetWalletsWithBalanceQueryVariables
>;
export const GetUserWalletsDocument = gql`
  query GetUserWallets(
    $limit: Int
    $offset: Int
    $filter: AdminGetUserWalletsFilterInput
    $order: SortOrder
    $orderBy: String
  ) {
    adminGetUserWallets(
      limit: $limit
      offset: $offset
      filter: $filter
      order: $order
      orderBy: $orderBy
    ) {
      userwallets {
        id
        updatedAt
        amount
        user {
          id
          firstName
          lastName
        }
        gateway {
          id
          name
          currency
          price
        }
      }
      count
      limit
      offset
    }
  }
`;

/**
 * __useGetUserWalletsQuery__
 *
 * To run a query within a React component, call `useGetUserWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWalletsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUserWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserWalletsQuery, GetUserWalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserWalletsQuery, GetUserWalletsQueryVariables>(
    GetUserWalletsDocument,
    options
  );
}
export function useGetUserWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserWalletsQuery, GetUserWalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserWalletsQuery, GetUserWalletsQueryVariables>(
    GetUserWalletsDocument,
    options
  );
}
export type GetUserWalletsQueryHookResult = ReturnType<typeof useGetUserWalletsQuery>;
export type GetUserWalletsLazyQueryHookResult = ReturnType<typeof useGetUserWalletsLazyQuery>;
export type GetUserWalletsQueryResult = Apollo.QueryResult<
  GetUserWalletsQuery,
  GetUserWalletsQueryVariables
>;
export const GetWithdrawalsDocument = gql`
  query GetWithdrawals($limit: Int, $offset: Int, $order: SortOrder, $orderBy: String) {
    adminGetUserWithdrawals(limit: $limit, offset: $offset, order: $order, orderBy: $orderBy) {
      limit
      count
      offset
      userwithdrawals {
        ...ListWithdrawalFragment
      }
    }
  }
  ${ListWithdrawalFragmentFragmentDoc}
`;

/**
 * __useGetWithdrawalsQuery__
 *
 * To run a query within a React component, call `useGetWithdrawalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWithdrawalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWithdrawalsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetWithdrawalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>(
    GetWithdrawalsDocument,
    options
  );
}
export function useGetWithdrawalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>(
    GetWithdrawalsDocument,
    options
  );
}
export type GetWithdrawalsQueryHookResult = ReturnType<typeof useGetWithdrawalsQuery>;
export type GetWithdrawalsLazyQueryHookResult = ReturnType<typeof useGetWithdrawalsLazyQuery>;
export type GetWithdrawalsQueryResult = Apollo.QueryResult<
  GetWithdrawalsQuery,
  GetWithdrawalsQueryVariables
>;
export const GetWithdrawalDocument = gql`
  query GetWithdrawal($id: String!) {
    adminGetUserWithdrawal(id: $id) {
      ...WithdrawalFragment
    }
  }
  ${WithdrawalFragmentFragmentDoc}
`;

/**
 * __useGetWithdrawalQuery__
 *
 * To run a query within a React component, call `useGetWithdrawalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWithdrawalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWithdrawalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWithdrawalQuery(
  baseOptions: Apollo.QueryHookOptions<GetWithdrawalQuery, GetWithdrawalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWithdrawalQuery, GetWithdrawalQueryVariables>(
    GetWithdrawalDocument,
    options
  );
}
export function useGetWithdrawalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWithdrawalQuery, GetWithdrawalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWithdrawalQuery, GetWithdrawalQueryVariables>(
    GetWithdrawalDocument,
    options
  );
}
export type GetWithdrawalQueryHookResult = ReturnType<typeof useGetWithdrawalQuery>;
export type GetWithdrawalLazyQueryHookResult = ReturnType<typeof useGetWithdrawalLazyQuery>;
export type GetWithdrawalQueryResult = Apollo.QueryResult<
  GetWithdrawalQuery,
  GetWithdrawalQueryVariables
>;
export const UpdateWithdrawStatusDocument = gql`
  mutation UpdateWithdrawStatus($input: UpdateWithdrawInput!) {
    adminUpdateWithdrawal(input: $input)
  }
`;
export type UpdateWithdrawStatusMutationFn = Apollo.MutationFunction<
  UpdateWithdrawStatusMutation,
  UpdateWithdrawStatusMutationVariables
>;

/**
 * __useUpdateWithdrawStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWithdrawStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWithdrawStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWithdrawStatusMutation, { data, loading, error }] = useUpdateWithdrawStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWithdrawStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWithdrawStatusMutation,
    UpdateWithdrawStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWithdrawStatusMutation, UpdateWithdrawStatusMutationVariables>(
    UpdateWithdrawStatusDocument,
    options
  );
}
export type UpdateWithdrawStatusMutationHookResult = ReturnType<
  typeof useUpdateWithdrawStatusMutation
>;
export type UpdateWithdrawStatusMutationResult =
  Apollo.MutationResult<UpdateWithdrawStatusMutation>;
export type UpdateWithdrawStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateWithdrawStatusMutation,
  UpdateWithdrawStatusMutationVariables
>;
