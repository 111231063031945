// ----------------------------------------------------------------------

// function path(root: string, sublink: string) {
//   return `${root}${sublink}`;
// }

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  page404: '/404'
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new')
  },
  document: {
    root: path(ROOTS_DASHBOARD, '/document'),
    list: path(ROOTS_DASHBOARD, '/document/list')
  },
  ticket: {
    root: path(ROOTS_DASHBOARD, '/ticket'),
    list: path(ROOTS_DASHBOARD, '/ticket/list'),
    create: path(ROOTS_DASHBOARD, '/ticket/new')
  },
  supportAdmin: {
    root: path(ROOTS_DASHBOARD, '/supportAdmin'),
    list: path(ROOTS_DASHBOARD, '/supportAdmin/list'),
    create: path(ROOTS_DASHBOARD, '/supportAdmin/new')
  },
  bankAccount: {
    root: path(ROOTS_DASHBOARD, '/bankAccount'),
    list: path(ROOTS_DASHBOARD, '/bankAccount/list')
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, '/transaction'),
    list: path(ROOTS_DASHBOARD, '/transaction/list')
  },
  gateway: {
    root: path(ROOTS_DASHBOARD, '/gateway'),
    list: path(ROOTS_DASHBOARD, '/gateway/list'),
    create: path(ROOTS_DASHBOARD, '/gateway/new')
  },
  bankGateway: {
    root: path(ROOTS_DASHBOARD, '/bank-gateway'),
    list: path(ROOTS_DASHBOARD, '/bank-gateway/list'),
    create: path(ROOTS_DASHBOARD, '/bank-gateway/new')
  },
  market: {
    root: path(ROOTS_DASHBOARD, '/market'),
    list: path(ROOTS_DASHBOARD, '/market/list'),
    create: path(ROOTS_DASHBOARD, '/market/new')
  },
  rate: {
    root: path(ROOTS_DASHBOARD, '/rate'),
    list: path(ROOTS_DASHBOARD, '/rate/list'),
    create: path(ROOTS_DASHBOARD, '/rate/new')
  },
  network: {
    root: path(ROOTS_DASHBOARD, '/network'),
    list: path(ROOTS_DASHBOARD, '/network/list'),
    create: path(ROOTS_DASHBOARD, '/network/new')
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order')
  },
  deposit: {
    root: path(ROOTS_DASHBOARD, '/deposit'),
    list: path(ROOTS_DASHBOARD, '/deposit/list')
  },
  withdrawal: {
    root: path(ROOTS_DASHBOARD, '/withdrawal'),
    list: path(ROOTS_DASHBOARD, '/withdrawal/list')
  },
  trade: {
    root: path(ROOTS_DASHBOARD, '/trade'),
    list: path(ROOTS_DASHBOARD, '/trade/list')
  },
  exchange: {
    root: path(ROOTS_DASHBOARD, '/exchange'),
    list: path(ROOTS_DASHBOARD, '/exchange/list')
  },
  announcement: {
    root: path(ROOTS_DASHBOARD, '/announcement'),
    list: path(ROOTS_DASHBOARD, '/announcement/list'),
    create: path(ROOTS_DASHBOARD, '/announcement/new')
  },
  appSlider: {
    root: path(ROOTS_DASHBOARD, '/app-slider'),
    list: path(ROOTS_DASHBOARD, '/app-slider/list'),
    create: path(ROOTS_DASHBOARD, '/app-slider/new')
  },
  totalWallets: {
    root: path(ROOTS_DASHBOARD, '/total-wallet'),
    list: path(ROOTS_DASHBOARD, '/total-wallet/list')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings')
  }
};
