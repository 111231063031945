// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const getIconify = (name: string) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('user'),
  ticket: getIconify('bx:support'),
  order: getIcon('cart-alt'),
  announcement: getIcon('news'),
  gateway: getIcon('credit-card'),
  market: getIconify('ph:airplay-bold'),
  deposit: getIconify('uil:money-insert'),
  withdrawal: getIconify('uil:money-withdraw'),
  totalWallets: getIconify('bxs:wallet'),
  setting: getIcon('cog'),
  admin: getIcon('user-rectangle'),
  appSlider: getIconify('ant-design:picture-filled'),
  transaction: getIconify('ep:list')
};

const sidebarConfig = [
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'dashboard',
    items: [
      {
        title: 'users',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'user list', path: PATH_DASHBOARD.user.list },
          { title: 'bank account list', path: PATH_DASHBOARD.bankAccount.list },
          { title: 'document list', path: PATH_DASHBOARD.document.list }
        ]
      },
      {
        title: 'tickets',
        path: PATH_DASHBOARD.ticket.list,
        icon: ICONS.ticket
      },
      {
        title: 'orders',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.order,
        children: [
          { title: 'trade list', path: PATH_DASHBOARD.trade.list },
          { title: 'exchange list', path: PATH_DASHBOARD.exchange.list }
        ]
      },
      {
        title: 'gateways',
        path: PATH_DASHBOARD.gateway.root,
        icon: ICONS.gateway,
        children: [
          { title: 'gateway list', path: PATH_DASHBOARD.gateway.list },
          { title: 'rates', path: PATH_DASHBOARD.rate.list },
          { title: 'crypto networks', path: PATH_DASHBOARD.network.list },
          { title: 'bank gateways', path: PATH_DASHBOARD.bankGateway.list }
        ]
      },
      {
        title: 'markets',
        path: PATH_DASHBOARD.market.list,
        icon: ICONS.market
      },
      {
        title: 'deposits',
        path: PATH_DASHBOARD.deposit.list,
        icon: ICONS.deposit
      },
      {
        title: 'withdrawals',
        path: PATH_DASHBOARD.withdrawal.list,
        icon: ICONS.withdrawal
      },
      {
        title: 'Total Wallets',
        path: PATH_DASHBOARD.totalWallets.root,
        icon: ICONS.totalWallets
      },
      {
        title: 'Transactions',
        path: PATH_DASHBOARD.transaction.root,
        icon: ICONS.transaction
      },
      {
        title: 'announcements',
        path: PATH_DASHBOARD.announcement.list,
        icon: ICONS.announcement
      },
      {
        title: 'sliders',
        path: PATH_DASHBOARD.appSlider.list,
        icon: ICONS.appSlider
      },
      {
        title: 'admins',
        path: PATH_DASHBOARD.supportAdmin.root,
        icon: ICONS.admin
      },
      {
        title: 'settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.setting
      }
    ]
  }
];

export default sidebarConfig;
