// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
// lightbox
import 'react-image-lightbox/style.css';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        {/* <ThemeLocalization> */}
        {/* <RtlLayout> */}
        <NotistackProvider>
          <GlobalStyles />
          <ProgressBarStyle />
          {/* <ScrollToTop />*/}
          {isInitialized ? <Router /> : <LoadingScreen />}
        </NotistackProvider>
        {/* </RtlLayout> */}
        {/* </ThemeLocalization> */}
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
