//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  return (
    <MAvatar
      alt={user?.firstName + ' ' + user?.lastName}
      color={createAvatar(user?.firstName + ' ' + user?.lastName).color}
      {...other}
    >
      {createAvatar(user?.firstName + ' ' + user?.lastName).name}
    </MAvatar>
  );
}
