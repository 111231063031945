import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { useAdminDetailLazyQuery, useAdminLoginMutation } from '../gql/graphql';
import { useApolloClient } from '@apollo/client';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  PreLogin = 'PRE_LOGIN',
  HideQR = 'HIDE_QR'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.PreLogin]: {
    email: string;
    password: string;
    qrValue?: string;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.HideQR]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  email: undefined,
  password: undefined,
  user: null,
  qrValue: undefined
};

const JWTReducer = (state: AuthState, action: JWTActions): AuthState => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        email: undefined,
        password: undefined,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'PRE_LOGIN':
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        qrValue: action.payload.qrValue
      };
    case 'HIDE_QR':
      return {
        ...state,
        qrValue: undefined
      };
    case 'LOGIN':
      return {
        ...state,
        email: undefined,
        password: undefined,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        email: undefined,
        password: undefined,
        isAuthenticated: false,
        user: null
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer<(state: AuthState, action: JWTActions) => AuthState>(
    JWTReducer,
    initialState
  );

  const client = useApolloClient();

  const [adminLoginMutation] = useAdminLoginMutation();

  const [adminDetailQuery] = useAdminDetailLazyQuery({
    onCompleted({ adminMe }) {
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user: adminMe
        }
      });
    },
    onError(err) {
      console.error('useAdminDetailLazyQuery', err);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  });

  useEffect(() => {
    const initialize = () => {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        adminDetailQuery();
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, [adminDetailQuery]);

  const preLogin = async (email: string, password: string, recaptcha: string) => {
    const { data } = await adminLoginMutation({
      variables: {
        input: {
          email,
          password,
          recaptcha
        }
      }
    });
    dispatch({
      type: Types.PreLogin,
      payload: {
        email,
        password,
        qrValue: data?.adminLogin?.otpAuthUrl as string
      }
    });
  };

  const login = async (token: string, recaptcha: string) => {
    if (state.email && state.password) {
      const { data } = await adminLoginMutation({
        variables: {
          input: {
            email: state.email,
            password: state.password,
            token,
            recaptcha
          }
        }
      });
      setSession(data?.adminLogin?.accessToken || null);
      dispatch({
        type: Types.Login,
        payload: {
          user: data?.adminLogin?.admin || null
        }
      });
    } else {
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: Types.Logout });
    client.resetStore();
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  const needVerification = !!state.email;

  const init = () =>
    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: false,
        user: null
      }
    });

  const hideQR = () =>
    dispatch({
      type: Types.HideQR
    });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        needVerification,
        preLogin,
        login,
        logout,
        resetPassword,
        updateProfile,
        init,
        hideQR
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
