import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function GuestGuard() {
  const { isAuthenticated, needVerification } = useAuth();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (isAuthenticated) {
      navigate(PATH_DASHBOARD.root);
    } else if (pathname === PATH_AUTH.login && needVerification) {
      navigate(PATH_AUTH.verify);
    } else if (pathname === PATH_AUTH.verify && !needVerification) {
      navigate(PATH_AUTH.login);
    }
  }, [needVerification, navigate, pathname, isAuthenticated]);

  return <Outlet />;
}
